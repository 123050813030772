import React, { useEffect, useState } from 'react'
import { callApi } from '../../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    }
  }
}

const viewEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/answers/view/answers/:id'
}

export default props => {
  const [addFiled, setAddFiled] = useState({})

  const callApiFunc = async () => {
    await callApi({ uriEndPoint: viewEndPoint, pathParams: { id: props?.match?.params?.id || null } })
      .then(res => {
        setAddFiled(res?.data || '')
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    callApiFunc()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Edit TypoForm</h4>
            <button className='btn btn-primary' onClick={() => window.history.back()}>
              Back
            </button>
          </div>

          <div className='container mt-5'>
            <h1 className='mb-1'>{addFiled?.answer_id}</h1>
            <h5 className='mb-4'>Type: {addFiled?.type}</h5>
            <div className='row'>
              {addFiled?.answers?.map((i, index) => {
                return (
                  <div className='col-md-6' key={i?._id}>
                    <h3>
                      Q{index + 1}: {i?.questions}
                    </h3>
                    <p>{i?.answer}</p>
                  </div>
                )
              })}
            </div>
          </div>

          {/* <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='form-group col-md-6'>
                <label for='inputEmail4'>Question</label>
                <input
                  type='text'
                  name='question'
                  value={addFiled?.question || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  placeholder='Question'
                />
              </div>
              <div className='form-group col-md-6'>
                <label for='inputPassword4'>Input Type</label>
                <select
                  id='inputState'
                  value={addFiled?.input_type || ''}
                  onChange={handelChange}
                  name='input_type'
                  className='form-control'
                >
                  <option selected>Choose...</option>
                  <option value='text'>Text</option>
                  <option value='email'>Email</option>
                  <option value='number'>Number</option>
                  <option value='phone'>phone</option>
                </select>
              </div>
              <div className='form-group col-md-6'>
                <label for='inputPassword4'>Error</label>
                <input
                  type='Text'
                  name='error'
                  value={addFiled?.error || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputPassword4'
                  placeholder='Error'
                />
              </div>
              <div className='form-group col-md-6'>
                <label for='inputState'>State</label>
                <select
                  id='inputState'
                  value={addFiled?.type || ''}
                  onChange={handelChange}
                  name='type'
                  className='form-control'
                >
                  <option selected>Choose...</option>
                  <option value='contact'>Contact</option>
                  <option value='course'>Course</option>
                </select>
              </div>
            </div>

            <button type='submit' className='btn btn-primary mt-2 d-none'>
              Submit
            </button>
          </form> */}
        </div>
      </div>
    </>
  )
}
