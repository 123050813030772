import React, { useEffect, useState } from 'react'

import { faCashRegister, faChartLine } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from '@themesberg/react-bootstrap'

import { CounterWidget, SalesValueWidget, SalesValueWidgetPhone } from '../../components/Widgets'
import { callApi } from '../../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const userListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/user'
}
const orderListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/orders/total-orders'
}

export default () => {
  const [users, setUsers] = useState([])
  const [inr, setINR] = useState('')
  const [usd, setUSD] = useState('')

  useEffect(() => {
    getUsers()
    getRevenue()
  }, [])

  async function getUsers() {
    await callApi({ uriEndPoint: userListEndPoint }).then(res => {
      if (res?.students) {
        setUsers(res.students)
      } else {
        setUsers([])
      }
    })
  }

  async function getRevenue() {
    await callApi({ uriEndPoint: orderListEndPoint }).then(res => {
      if (res?.orders) {
        const tempDoc = res.orders

        let Indian = tempDoc
          .filter(
            e =>
              (e.category === 'indian' || e.category === 'homeopaths' || e.category === 'indian_students') &&
              e.studentname !== 'ketan' &&
              e.studentemail !== 'doctorakanshayadav@gmail.com'
          )
          .map(i => Number(i.amount / 100))
          .reduce((a, b) => Number(a) + Number(b), 0)
          .toFixed(2)

        setINR(Indian)

        let International = tempDoc
          .filter(e => e.category === 'international')
          .map(i => Number(i.amount))
          .reduce((a, b) => Number(a) + Number(b), 0)

        // let alpas = 0
        // const pro_user = tempDoc.filter(e => e.category === 'international')
        // pro_user.map(ele => {
        //   alpas += parseInt(ele.amount)
        // })

        setUSD(International)
      } else {
        setINR(0)

        setUsers([])
      }
    })

    //  await app
    //   .firestore()
    //   .collection('orders')
    //   .onSnapshot(querySnapshot => {
    //     const tempDoc = querySnapshot.docs.map(doc => {
    //       return { id: doc.id, ...doc.data() }
    //     })

    //     let alpa = 0
    //     // let inr_user = tempDoc.filter(e => e.category === 'student')
    //     const newAmountArray = []

    //     for (let i = 0; i < tempDoc?.length; i++) {
    //       const element = tempDoc[i]
    //       if (element.studentname === 'ketan' || element.studentname === 'ketanpatel') {
    //       } else {
    //         if (element.category === 'student') {
    //           newAmountArray.push(element)
    //           // inr_user = tempDoc.filter(e => e.category === 'student')
    //         }
    //       }
    //     }
    //     newAmountArray.map(ele => {
    //       alpa += parseInt(ele.amount)
    //     })
    //     setINR(alpa)

    //     let alpas = 0
    //     const pro_user = tempDoc.filter(e => e.category === 'pro')
    //     pro_user.map(ele => {
    //       alpas += parseInt(ele.amount)
    //     })

    //     setUSD(alpas)
    //   })
  }

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const d = new Date()

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'></div>

      <Row className='justify-content-md-center'>
        <Col xs={12} className='mb-4 d-none d-sm-block' style={{ height: '100%' }}>
          <SalesValueWidget title={monthNames[d.getMonth()] + ' ' + 'Sales Value'} value='230' percentage={45} />
        </Col>
        <Col xs={12} className='mb-4 d-sm-none' style={{ height: '100%' }}>
          <SalesValueWidgetPhone title='Sales Value' value='10,567' percentage={10.57} />
        </Col>
        <Col xs={12} sm={6} xl={4} className='mb-4'>
          <CounterWidget
            category='Total Students'
            title={users.length.toString()}
            period=''
            percentage={0}
            icon={faChartLine}
            iconColor='shape-secondary'
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className='mb-4'>
          <CounterWidget
            category='Total Revenue INR'
            title={`₹${inr}`}
            period=''
            percentage={0}
            icon={faCashRegister}
            iconColor='shape-tertiary'
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className='mb-4'>
          <CounterWidget
            category='Total Revenue USD'
            title={'$' + usd}
            period=''
            percentage={0}
            icon={faCashRegister}
            iconColor='shape-tertiary'
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} xl={12} className='mb-4'>
          <Row>
            <Col xs={12} xl={8} className='mb-4'>
              <Row>
                {/* <Col xs={12} className="mb-4">
                  <PageVisitsTable />
                </Col> */}

                {/* <Col xs={36} className='mb-12'>
                  <TeamMembersWidget />
                </Col> */}

                {/* <Col xs={12} lg={6} className="mb-4">
                  <ProgressTrackWidget />
                </Col> */}
              </Row>
            </Col>

            {/* <Col xs={12} xl={4}>
              <Row>
                <Col xs={12} className="mb-4">
                  <BarChartWidget
                    title="Total orders"
                    value={452}
                    percentage={18.2}
                    data={totalOrders} />
                </Col>

           
              </Row>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </>
  )
}
