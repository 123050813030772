import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'

import { CSVLink } from 'react-csv'

import { StudentsReportTable } from '../../components/free-course-table'
import { callApi } from '../../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const universityListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/university/list'
}

const studentReportListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/free-course-user/list'
}

export default props => {
  const id = props.match.params.id

  const [data, setData] = useState([])
  const [universityList, setUniversityList] = useState(null)
  const [selectedUniversity, setSelectedUniversity] = useState('all')
  const [courses, setCourses] = useState([])
  // const [recallApi, setRecallApi] = useState('')

  async function getData() {
    setData(courses)
    // await callApi({ uriEndPoint: userListEndPoint })
    //   .then(res => {
    //     if (res?.students) {
    //       setData(res.students)
    //     }
    //   })
    //   .catch(e => console.log(e))
  }

  useEffect(() => {
    callApi({ uriEndPoint: universityListEndPoint }).then(res => {
      setUniversityList(res)
    })
  }, [])

  const handelChange = e => {
    // Update form field value
    setSelectedUniversity(e.target.value)
  }

  useEffect(() => {
    if (selectedUniversity) {
      Refresh()
    }
  }, [selectedUniversity])

  async function Refresh() {
    await callApi({
      uriEndPoint: studentReportListEndPoint,
      query: { university_id: selectedUniversity === 'all' ? '' : selectedUniversity || ' ' }
    })
      .then(res => {
        if (res?.getFreeUserData) {
          setCourses(res.getFreeUserData)
        }
      })
      .catch(e => console.log(e))
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Students</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Students</h4>
        </div>
      </div>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2'>
        <p className='mb-0 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center'>
          SHAH Students Management.
          <div className='ms-2'>
            {data?.length !== 0 ? (
              <CSVLink data={data}>Download Ready</CSVLink>
            ) : (
              <button onClick={getData} className='btn btn-info'>
                Make CSV
              </button>
            )}
          </div>
        </p>

        <div className='form-group col-md-2'>
          <label htmlFor='inputPassword4'>Select University</label>
          <select
            id='inputState'
            value={selectedUniversity}
            onChange={handelChange}
            name='university_id'
            className='form-control'
          >
            <option value={'all'}>All</option>
            {/* Populate options dynamically */}
            {universityList &&
              universityList?.allUniversity?.map(university => (
                <option key={university?._id} value={university?._id}>
                  {university?.name}
                </option>
              ))}
          </select>
        </div>
      </div>

      <StudentsReportTable id={id} selectedUniversity={selectedUniversity} courses={courses} />
    </>
  )
}
