import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Breadcrumb, Button, Col, Form, Row } from '@themesberg/react-bootstrap'

import { UserReportListTable } from '../components/Tables'
import { callApi } from '../utils/api-utils'
import { CSVLink } from 'react-csv'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const userReportListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/user/get-by-type'
}

export default props => {
  const id = props.match.params.id
  const [selectStudent, setSelectStudent] = useState('')

  const [userData, setUserData] = useState([])

  const clickToCallApi = async () => {
    await callApi({ uriEndPoint: userReportListEndPoint, query: { student_select: selectStudent || ' ' } })
      .then(res => {
        if (res?.data) {
          const newVAlue = res?.data

          const mapData = newVAlue.map(i => {
            delete i.isAdmin
            delete i.otp
            delete i.otp_expired_time
            delete i.password
            delete i.profile_pic
            delete i.__v
            delete i.verified

            for (let x in i) {
              if (!i[x]) {
                i[x] = '-'
              }
            }
            return i
          })

          setUserData(mapData)
        } else {
          setUserData([])
        }
      })
      .catch(e => console.log(e))
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Users Report</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Users Report</h4>
          <div className='d-flex justify-content-between  align-items-center'>
            <p className='mb-0'>SHAH Users Report Management.</p>
          </div>
        </div>
      </div>

      <Row style={{ gap: '10px', margin: '1rem 0px' }}>
        <Col xl={8} style={{ padding: '0px' }}>
          <Form.Select aria-label='Default select example' onChange={e => setSelectStudent(e.target.value)}>
            <option>Open this select student</option>
            <option value='indian_students'>Indian Students</option>
            <option value='indian_homeopathic_practitioners'>Indian Homeopathic Practitioners</option>
            <option value='international_homeopathic_students'>International Homeopathic Students</option>
          </Form.Select>
        </Col>
        <Col xl={3} style={{ display: 'flex', gap: '10px' }}>
          <Button onClick={() => clickToCallApi()}>Search</Button>
          {userData.length ? (
            <CSVLink data={userData}>
              <Button>Export</Button>
            </CSVLink>
          ) : null}
        </Col>
      </Row>
      <UserReportListTable id={id} userData={userData} />
    </>
  )
}
