import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Breadcrumb } from '@themesberg/react-bootstrap'

import { StudentsTable } from '../components/Tables'
import { CSVLink } from 'react-csv'

import { callApi } from '../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const userListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/user'
}

export default props => {
  const id = props.match.params.id

  const [data, setData] = useState([])

  async function getData() {
    await callApi({ uriEndPoint: userListEndPoint })
      .then(res => {
        if (res?.students) {
          setData(res.students)
        }
      })
      .catch(e => console.log(e))
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Students</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Students</h4>
          <p className='mb-0'>
            SHAH Students Management.
            {data.length !== 0 ? (
              <CSVLink data={data}>Download Ready</CSVLink>
            ) : (
              <button onClick={getData} className='btn btn-info'>
                Make CSV
              </button>
            )}
          </p>
        </div>
      </div>

      <StudentsTable id={id} />
    </>
  )
}
