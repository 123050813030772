import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb } from '@themesberg/react-bootstrap'
import React from 'react'

import { FreeCourseTable } from '../components/Tables'

export default () => {
  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Free Course</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Free Course</h4>
          <p className='mb-0'>
            SHAH free course management. <a href={'#/create-free-course'}>Create Free Course</a>
          </p>
        </div>
      </div>

      <FreeCourseTable />
    </>
  )
}
