import React, { useState } from 'react'
import { callApi } from '../../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const addEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/questions/questions'
}

export default () => {
  const [addFiled, setAddFiled] = useState({ question: '', input_type: '', error: '', type: '' })
  const [errorHandel, setErrorHandel] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    const checkValue = Object.values(addFiled).every(v => v)
    if (checkValue) {
      await callApi({ uriEndPoint: addEndPoint, body: addFiled })
        .then(res => {
          alert(res?.message || 'Add question successfully')
          setAddFiled({})
          //   window.history.back()
        })
        .catch(e => console.log(e))
    } else {
      setErrorHandel(true)
    }
  }

  const handelChange = e => {
    setAddFiled({ ...addFiled, [e.target.name]: e.target.value })
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Create TypoForm</h4>
            <button className='btn btn-primary' onClick={() => window.history.back()}>
              Back
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4'>Question *</label>
                <input
                  type='text'
                  name='question'
                  value={addFiled?.question || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  placeholder='Question'
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputPassword4'>Input Type *</label>
                <select
                  id='inputState'
                  value={addFiled?.input_type || ''}
                  onChange={handelChange}
                  name='input_type'
                  className='form-control'
                >
                  <option selected>Choose...</option>
                  <option value='text'>Text</option>
                  <option value='email'>Email</option>
                  <option value='number'>Number</option>
                  <option value='phone'>phone</option>
                </select>
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputPassword4'>Error *</label>
                <input
                  type='Text'
                  name='error'
                  value={addFiled?.error || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputPassword4'
                  placeholder='Error'
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputState'>Type *</label>
                <select
                  id='inputState'
                  value={addFiled?.type || ''}
                  onChange={handelChange}
                  name='type'
                  className='form-control'
                >
                  <option selected>Choose...</option>
                  <option value='contact'>Contact</option>
                  <option value='course'>Course</option>
                </select>
              </div>
            </div>
            {errorHandel && (
              <h5 style={{ color: 'orangered', textTransform: 'capitalize', marginTop: '10px' }}>* require filed</h5>
            )}
            <button type='submit' className='btn btn-primary mt-2'>
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  )
}
