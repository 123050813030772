import React, { useState, useEffect } from 'react'
import { Col, Row, Form, Container } from '@themesberg/react-bootstrap'

import axios from 'axios'

export default props => {
  const [fireid] = useState(props.match.params.id)
  const [data, setData] = useState([])

  useEffect(() => {
    UserData()
  }, [fireid])

  async function UserData() {
    await axios
      .get(`/api/webinar/getWebinar/${fireid}`)
      .then(res => setData(res.data.data))
      .catch(e => alert(e))
  }

  return (
    <article>
      <Container className='px-0'>
        <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
          <Col className='d-block mb-4 mb-md-0'>
            <h1 className='h2'>Free Webinar</h1>
            <p className='mb-0'>
              Here you can view a Free Webinar details <a href={'#/free-webinar'}>Back to Free Webinar</a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className='d-block mb-6 mb-md-0' xs={6}>
            {' '}
            <Form.Label>Name : {data.name}</Form.Label>
            <br />
            <Form.Label>Email : {data.email} </Form.Label>
            <br />
            <Form.Label>Phone : {data.phone}</Form.Label>
            <br />
            <Form.Label> Country : {data.country}</Form.Label>
            <br />
            <Form.Label> Questions : {data.questions}</Form.Label>
            <br />
            <Form.Label>Certificate Name : {data.certificate_name}</Form.Label>
            <br />
          </Col>
          {/* <Col className='d-block mb-6 mb-md-0' xs={6}>
            {' '}
            <Form.Label>College: {data.college}</Form.Label>
            <br />
            <Form.Label>College City: {data.college_city}</Form.Label>
            <br />
            <Form.Label>College State: {data.college_state}</Form.Label>
            <br />
            <Form.Label>Course: {data.course}</Form.Label>
            <br />
          </Col> */}
        </Row>
        <button
          className='btn btn-info'
          style={{ marginTop: 12 }}
          onClick={() => (window.location.href = '#/free-webinar')}
        >
          Back
        </button>
      </Container>
    </article>
  )
}
