import React, { useState, useEffect } from 'react'
import { Col, Row, Form, Container } from '@themesberg/react-bootstrap'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import S3FileUpload from 'react-s3'
window.Buffer = window.Buffer || require('buffer').Buffer

import axios from 'axios'
import { callApi } from '../../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const coachListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/staff/staff-list'
}

export default props => {
  const [nameOfCourse, setnameOfCourse] = useState('')
  const [slugValue, setSlugValue] = useState('')
  const [oneLineSentence, setOneLineSentence] = useState('')

  const [Img, setImg] = useState('')
  const [RegularPrice, setRegularPrice] = useState('')
  const [SellingPrice, setSellingPrice] = useState('')
  const [appleSellingPrice, setAppleSellingPrice] = useState('')

  const [DollarRegularPrice, setDollarRegularPrice] = useState('')
  const [DollarSellingPrice, setDollarSellingPrice] = useState('')
  const [appleDollarSellingPrice, setAppleDollarSellingPrice] = useState('')

  const [tutorName, setTutorName] = useState('')
  const [tutorImg, setTutorImg] = useState('')
  const [tutorBio, setTutorBio] = useState('')
  const [published, setPublished] = useState('')
  const [isPaid, setIsPaid] = useState('')
  const [rating, setRating] = useState('')

  const [id] = useState(props.match.params.id)
  const [
    ,
    // loading
    setLoading
  ] = useState('Load Course')

  // const ids= props.match.params.id
  const [notes, setNumberOfNotes] = useState('')
  const [certification, setCertificationType] = useState('')
  const [overview, setOverview] = useState('')
  const [perview, setPreview] = useState('')
  const [serial, setSerial] = useState('')
  const [category, setCategory] = useState('')

  const [clinicalPoints, setclinicalPoints] = useState('')
  const [BadgeHonor, setBadgeHonor] = useState('')
  const [liveShah, setliveShah] = useState('')
  const [WatchCourse, setWatchCourse] = useState('')
  const [CourseAccess, setCourseAccess] = useState('')
  const [ChatwithCoach, setChatwithCoach] = useState('')
  const [TotalDuration, setTotalDuration] = useState('')
  const [Language, setLanguage] = useState('')
  const [SkillLevel, setSkillLevel] = useState('')
  const [divide, setDivide] = useState('')
  const [HeroImg, setHeroImg] = useState('')
  const [courseOverviewThumb, setcourseOverviewThumb] = useState('')
  const [courseCurriculum, setCourseCurriculum] = useState('')
  const [awsoverview, setAwsOverview] = useState('')
  const [homeoPathiRegularPrice, setHomeoPathiRegularPrice] = useState('')
  const [homeoPathiSellPrice, setHomeoPathiSellPrice] = useState('')

  const [selectCoach, setSelectCoach] = useState('')
  const [coursePublished, setCoursePublished] = useState('publish')

  const [staffList, setStaffList] = useState(null)

  useEffect(() => {
    LoadCourses(id)
  }, [])

  async function Refresh() {
    await callApi({ uriEndPoint: coachListEndPoint })
      .then(res => {
        if (res?.data) {
          setStaffList(res?.data)
        }
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    Refresh()
  }, [])
  const config = {
    bucketName: 'courseimg',
    region: 'ap-south-1',
    accessKeyId: 'AKIAZTNEOKHHTM2HHSWZ',
    secretAccessKey: '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'
  }

  function UploadImage(event) {
    const image = event.target.files[0]
    S3FileUpload.uploadFile(image, config)
      .then(data => {
        setImg(data.location)
      })
      .catch(err => console.error(err))
  }

  function UploadImages(event) {
    const image = event.target.files[0]
    S3FileUpload.uploadFile(image, config)
      .then(data => {
        setHeroImg(data.location)
        console.log(data.location)
      })
      .catch(err => console.error(err))
  }

  function UploadImagesH(event) {
    const image = event.target.files[0]

    S3FileUpload.uploadFile(image, config)
      .then(data => {
        console.log('thumbOverview-S3FileUpload', data)
        setcourseOverviewThumb(data.location)
        console.log(data.location)
      })
      .catch(err => console.error(err))
  }

  function UploadCourseCurriculum(event) {
    const image = event.target.files[0]
    console.log(image)
    S3FileUpload.uploadFile(image, config)
      .then(data => {
        setCourseCurriculum(data.location)
        console.log(data.location)
      })
      .catch(err => console.error(err))
  }

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const handleEditorChange = state => {
    setEditorState(state)
  }

  function RecieveData(html) {
    const contentBlock = htmlToDraft(html)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      const editorState = EditorState.createWithContent(contentState)
      setEditorState(editorState)
    }
  }

  function LoadCourses() {
    setLoading('Loading ...')
    axios
      .post(`/api/course/get/${id}`)
      .then(res => {
        setLoading('Data Loaded')
        // alert(' Course Data Loaded')
        console.log('thumbOverview-view-res', res)
        setSelectCoach(res?.data?.select_coach || '')
        setCoursePublished(res?.data?.is_published === false ? 'draft' : 'publish' || '')
        setSlugValue(res?.data?.slug || '')
        setOneLineSentence(res?.data?.one_line_sentence || '')
        setnameOfCourse(res.data.nameOfCourse)
        setIsPaid(res.data.isPaid === true ? 'yes' : 'no')
        setPublished(res.data.published === true ? 'yes' : 'no')
        setImg(res.data.Img)
        setTutorBio(res.data.tutorBio)
        setTutorImg(res.data.tutorImg)
        setTutorName(res.data.tutorName)
        setSellingPrice(res.data.SellingPrice)
        setRegularPrice(res.data.RegularPrice)
        RecieveData(res.data.description)
        setRating(res.data.rating)
        // setEditorState(res.data.description)

        setNumberOfNotes(res.data.notes)
        setCertificationType(res.data.certification)
        setOverview(res.data.overview)
        setPreview(res.data.preview)
        setSerial(res.data.serial)
        setCategory(res.data.category)

        setDollarRegularPrice(res.data.DollarRegularPrice)
        setDollarSellingPrice(res.data.DollarSellingPrice)
        setHomeoPathiRegularPrice(res.data.HomeopathicRegularPrice)
        setHomeoPathiSellPrice(res.data.HomeopathicSellingPrice)

        setclinicalPoints(res.data.clinicalPoints)
        setBadgeHonor(res.data.BadgeHonor)
        setliveShah(res.data.liveShah)
        setWatchCourse(res.data.WatchCourse)
        setCourseAccess(res.data.CourseAccess)
        setChatwithCoach(res.data.ChatwithCoach)
        setTotalDuration(res.data.TotalDuration)
        setLanguage(res.data.Language)
        setSkillLevel(res.data.SkillLevel)
        setDivide(res.data.divide)
        setHeroImg(res.data.banners)
        setcourseOverviewThumb(res.data.courseOverviewThumb)
        setCourseCurriculum(res.data.courseCurriculum)
        setAwsOverview(res.data.awsoverview)
        setAppleDollarSellingPrice(res.data.appleDollarSellingPrice)
        setAppleSellingPrice(res.data.appleSellingPrice)
      })
      .catch(e => {
        // setLoading('Data Load')
        alert(e)
      })
  }

  function CourseCreation(e) {
    e.preventDefault()
    if (Img.length !== 0) {
      convertContentToHTML()
    } else {
      alert('Course Image not found')
    }
  }

  // function convertToSlug(Text) {
  //   return Text.toLowerCase()
  //     .replace(/ /g, '-')
  //     .replace(/[^\w-]+/g, '')
  // }

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    axios
      .post(`/api/course/update`, {
        id: id,
        isPaid: isPaid.toLowerCase() === 'yes' ? true : false,
        published: published.toLowerCase() === 'yes' ? true : false,
        nameOfCourse: nameOfCourse,
        slug: slugValue,
        one_line_sentence: oneLineSentence,
        Img: Img,
        description: currentContentAsHTML,
        select_coach: selectCoach,
        RegularPrice: RegularPrice,
        SellingPrice: SellingPrice,
        appleSellingPrice: appleSellingPrice,
        rating: rating,
        HomeopathicRegularPrice: homeoPathiRegularPrice,
        HomeopathicSellingPrice: homeoPathiSellPrice,
        is_published: coursePublished === 'draft' ? false : true,
        DollarRegularPrice: DollarRegularPrice,
        DollarSellingPrice: DollarSellingPrice,
        appleDollarSellingPrice: appleDollarSellingPrice,

        tutorName: tutorName,
        tutorImg: tutorImg,
        tutorBio: tutorBio,
        courseCurriculum: courseCurriculum,

        certification: certification,
        notes: notes,
        overview: overview,
        preview: perview,
        serial: serial,
        category: category,

        clinicalPoints: clinicalPoints,
        BadgeHonor: BadgeHonor,
        liveShah: liveShah,
        WatchCourse: WatchCourse,
        CourseAccess: CourseAccess,
        ChatwithCoach: ChatwithCoach,
        TotalDuration: TotalDuration,
        Language: Language,
        SkillLevel: SkillLevel,
        divide: divide,
        banners: HeroImg,
        courseOverviewThumb: courseOverviewThumb,
        awsoverview: awsoverview
      })
      .then(res => {
        alert('Course Updated')
        console.log('thumbOverview-update-res', res)

        window.location.href = '#/all-courses'
      })
      .catch(e => alert(e))
  }

  return (
    <article>
      <Container className='px-0'>
        <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
          <Col className='d-block mb-4 mb-md-0'>
            <h1 className='h2'>Edit Course</h1>
            <p className='mb-0'>Here you can edit course for SHAH</p>
          </Col>
        </Row>

        <Form onSubmit={CourseCreation}>
          <Form.Group className='mb-3'>
            <Form.Label>Course Name</Form.Label>
            <Form.Control
              type='text'
              value={nameOfCourse}
              onChange={e => setnameOfCourse(e.target.value)}
              placeholder='Your Course name'
            />
            <Form.Label>Course Slug</Form.Label>
            <Form.Control
              type='text'
              value={slugValue}
              onChange={e => setSlugValue(e.target.value)}
              placeholder='Your Course name'
            />
            <Form.Label>One Line Sentence</Form.Label>
            <Form.Control
              type='text'
              value={oneLineSentence}
              onChange={e => setOneLineSentence(e.target.value)}
              placeholder='One Line Sentence'
            />

            <Form.Label>Rating</Form.Label>
            <Form.Control
              type='number'
              value={rating}
              onChange={e => setRating(Number(e.target.value))}
              placeholder='Rating'
              onInput={e => {
                e.target.value = e.target.value.slice(0, 1)
              }}
            />
            <Form.Label>Break From</Form.Label>
            <Form.Control
              type='text'
              value={divide}
              onChange={e => setDivide(e.target.value)}
              placeholder='Break From'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Add Description</Form.Label>

            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName='demo-wrapper'
              editorClassName='demo-editor'
              toolbarClassName='toolbar-class'
            />

            <Form.Group style={{ marginTop: 4 }} className='mb-3'>
              <Form.Label>Details</Form.Label>
              <br />
              <Form.Label style={{ marginTop: 12 }}>Course Img URL</Form.Label> <br />
              {Img.length === 0 ? (
                <input onChange={UploadImage} style={{ marginTop: 4 }} type='file' placeholder='Course Img URL' />
              ) : (
                <>
                  <p className='btn btn-success'>Image Added</p>
                  <p
                    onClick={() => {
                      setImg('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='btn btn-danger'
                  >
                    Replace
                  </p>
                </>
              )}
              <br />
              <Form.Label style={{ marginTop: 12 }}>Course Hero Img</Form.Label> <br />
              {HeroImg?.length === 0 ? (
                <input onChange={UploadImages} style={{ marginTop: 4 }} type='file' placeholder='Course Img URL' />
              ) : (
                <>
                  <p className='btn btn-success'>Image Added</p>
                  <p
                    onClick={() => {
                      setHeroImg('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='btn btn-danger'
                  >
                    Replace
                  </p>
                </>
              )}
              <br />
              <Form.Label style={{ marginTop: 12 }}>Curriculum PDF </Form.Label> <br />
              {courseCurriculum?.length === 0 ? (
                <input
                  onChange={UploadCourseCurriculum}
                  style={{ marginTop: 4 }}
                  type='file'
                  placeholder='Course Img URL'
                />
              ) : (
                <>
                  <p className='btn btn-success'>Curriculum Added</p>
                  <p
                    onClick={() => {
                      setCourseCurriculum('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='btn btn-danger'
                  >
                    Replace
                  </p>
                </>
              )}
              <br />
              <Form.Label style={{ marginTop: 12 }}>Course Overview Thumbnail</Form.Label> <br />
              {courseOverviewThumb?.length === 0 ? (
                <input
                  onChange={UploadImagesH}
                  style={{ marginTop: 4 }}
                  type='file'
                  placeholder='Course Overview URL'
                />
              ) : (
                <>
                  <p className='btn btn-success'>Image Added</p>
                  <p
                    onClick={() => {
                      setcourseOverviewThumb('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='btn btn-danger'
                  >
                    Replace
                  </p>
                </>
              )}
              <br />
              <Form.Label style={{ marginTop: 12 }}>Coach List</Form.Label>
              <Form.Select
                value={selectCoach}
                onChange={e => setSelectCoach(e.target.value)}
                aria-label='Default select example'
              >
                <option>Open this select coach</option>
                {staffList &&
                  staffList.map(i => {
                    return <option value={i._id}>{i.first_name + ' ' + i.last_name}</option>
                  })}
              </Form.Select>
              <Form.Label style={{ marginTop: 12 }}>Course Certification (Diploma or Fellowship)</Form.Label>
              <Form.Control
                value={certification}
                onChange={e => setCertificationType(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Certification Type'
              />
              <Form.Label style={{ marginTop: 12 }}>Clinical Quiz</Form.Label>
              <Form.Control
                value={certification}
                onChange={e => setCertificationType(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Certification Type'
              />
              <Form.Label style={{ marginTop: 12 }}>Download Course Notes</Form.Label>
              <Form.Control
                value={notes}
                onChange={e => setNumberOfNotes(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Total Number of Notes'
              />
              <Form.Label style={{ marginTop: 12 }}>Course Overview Website (Vdocipher Video Link) </Form.Label>
              <Form.Control
                value={overview}
                onChange={e => setOverview(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Website Overview Vdocipher URL'
              />
              <Form.Label style={{ marginTop: 12 }}>Course Overview App (Vdocipher Id) </Form.Label>
              <Form.Control
                value={awsoverview}
                onChange={e => setAwsOverview(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='App Overview AWS URL'
              />
              <Form.Label style={{ marginTop: 12 }}>Course Preview Youtube URL</Form.Label>
              <Form.Control
                value={perview}
                onChange={e => setPreview(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Course Preview URL'
              />
              <Form.Label style={{ marginTop: 12 }}>
                Indian Students Regular Price <b>(INR)</b>
              </Form.Label>
              <Form.Control
                value={RegularPrice}
                onChange={e => setRegularPrice(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Course Regular Price'
              />
              <Form.Label style={{ marginTop: 12 }}>
                Indian Students Discounted Price <b>(INR)</b>
              </Form.Label>
              <Form.Control
                value={SellingPrice}
                onChange={e => setSellingPrice(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Course Selling Price'
              />
              <Form.Label style={{ marginTop: 12 }}>
                Indian Homeopaths Regular Price <b>(INR)</b>
              </Form.Label>
              <Form.Control
                value={homeoPathiRegularPrice}
                onChange={e => setHomeoPathiRegularPrice(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Course Selling Price'
              />
              <Form.Label style={{ marginTop: 12 }}>
                Indian Homeopaths Discounted Price <b>(INR)</b>
              </Form.Label>
              <Form.Control
                value={homeoPathiSellPrice}
                onChange={e => setHomeoPathiSellPrice(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Course Regular Price'
              />
              <Form.Label style={{ marginTop: 12 }}>
                International Students Regular Price <b>(Dollar)</b>
              </Form.Label>
              <Form.Control
                value={DollarRegularPrice}
                onChange={e => setDollarRegularPrice(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Course Regular Price'
              />
              <Form.Label style={{ marginTop: 12 }}>
                International Students Discounted Price <b>(Dollar)</b>
              </Form.Label>
              <Form.Control
                value={DollarSellingPrice}
                onChange={e => setDollarSellingPrice(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Course Selling Price'
              />
              <Form.Label style={{ marginTop: 12 }}>
                Selling Price for Apple <b>(INR)</b>
              </Form.Label>
              <Form.Control
                value={appleSellingPrice}
                onChange={e => setAppleSellingPrice(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Apple Course Selling Price'
              />
              <Form.Label style={{ marginTop: 12 }}>
                Apple Selling Price <b>(Dollar)</b>
              </Form.Label>
              <Form.Control
                value={appleDollarSellingPrice}
                onChange={e => setAppleDollarSellingPrice(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Apple Course Selling Price'
              />
              <Form.Label style={{ marginTop: 12 }}>Tutor Name</Form.Label>
              <Form.Control
                value={tutorName}
                onChange={e => setTutorName(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Tutor Name'
              />
              <Form.Label style={{ marginTop: 12 }}>Tutor Img</Form.Label>
              <Form.Control
                value={tutorImg}
                onChange={e => setTutorImg(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Tutor Img URL'
              />
              <Form.Label style={{ marginTop: 12 }}>Tutor Bio</Form.Label>
              <Form.Control
                value={tutorBio}
                onChange={e => setTutorBio(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Tutor Bio'
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label> Course Mains ( YES / NO )</Form.Label>
              <br />

              <Form.Label style={{ marginTop: 12 }}>Paid Course</Form.Label>
              <Form.Control
                value={isPaid}
                onChange={e => setIsPaid(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Paid Course (YES / NO)'
              />

              <Form.Label style={{ marginTop: 12 }}>Published</Form.Label>
              <Form.Control
                value={published}
                onChange={e => setPublished(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Published (YES / NO )'
              />

              <Form.Label style={{ marginTop: 12 }}>Category</Form.Label>
              <Form.Control
                value={category}
                onChange={e => setCategory(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Category'
              />

              <Form.Label style={{ marginTop: 12 }}>Summary of Clinical Points to learn</Form.Label>
              <Form.Control
                value={clinicalPoints}
                onChange={e => setclinicalPoints(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Summary of Clinical Points to learn'
              />

              <Form.Label style={{ marginTop: 12 }}>Badge Honor (Yes/No)</Form.Label>
              <Form.Control
                value={BadgeHonor}
                onChange={e => setBadgeHonor(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Badge Honor (Yes/No)'
              />

              <Form.Label style={{ marginTop: 12 }}>Live with Dr. Krutik Shah </Form.Label>
              <Form.Control
                value={liveShah}
                onChange={e => setliveShah(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Live with Dr. Krutik Shah'
              />

              <Form.Label style={{ marginTop: 12 }}>Buy the Course Chapterwise</Form.Label>
              <Form.Control
                value={WatchCourse}
                onChange={e => setWatchCourse(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Category'
              />

              <Form.Label style={{ marginTop: 12 }}>Course Access</Form.Label>
              <Form.Control
                value={CourseAccess}
                onChange={e => setCourseAccess(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Category'
              />

              <Form.Label style={{ marginTop: 12 }}>Chat with Coach</Form.Label>
              <Form.Control
                value={ChatwithCoach}
                onChange={e => setChatwithCoach(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Category'
              />

              <Form.Label style={{ marginTop: 12 }}>TotalDuration</Form.Label>
              <Form.Control
                value={TotalDuration}
                onChange={e => setTotalDuration(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Category'
              />

              <Form.Label style={{ marginTop: 12 }}>Language</Form.Label>
              <Form.Control
                value={Language}
                onChange={e => setLanguage(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Category'
              />

              <Form.Label style={{ marginTop: 12 }}>Skill Level</Form.Label>
              <Form.Control
                value={SkillLevel}
                onChange={e => setSkillLevel(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Category'
              />

              <Form.Label style={{ marginTop: 12 }}>Course Position</Form.Label>
              <Form.Control
                value={serial}
                onChange={e => setSerial(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Course Position for example 1'
              />
              <Form.Label style={{ marginTop: 12 }}>Course Published</Form.Label>
              <Form.Select
                value={coursePublished}
                onChange={e => setCoursePublished(e.target.value)}
                aria-label='Default select example'
              >
                <option value={'publish'}>Published</option>
                <option value={'draft'}>Draft</option>
              </Form.Select>
            </Form.Group>

            <button className='btn btn-info' type='submit'>
              {' '}
              Update Course
            </button>
          </Form.Group>
        </Form>
      </Container>
    </article>
  )
}
