import React, { useState, useEffect } from 'react'
import { Col, Row, Form, Container } from '@themesberg/react-bootstrap'

import axios from 'axios'

export default props => {
  const [fireid] = useState(props.match.params.id)
  const [data, setData] = useState([])

  useEffect(() => {
    UserData()
  }, [fireid])

  async function UserData() {
    await axios
      .post('/api/user/u', {
        fireid: fireid
      })
      .then(res => setData(res.data))
      .catch(e => alert(e))
  }

  async function UpdateStudent(e) {
    e.preventDefault()

    alert('Verifying Documents ')
    await axios
      .post('/api/user/update', {
        fireid: data.fireid,
        name: data.name,
        email: data.email,
        password: data.password,
        type: data.type,
        verified: true,
        phone: data.phone,
        dob: data.dob,
        address: data.address,
        city: data.city,
        state: data.state,
        country: data.country,
        documentType: data.documentType,
        frontUrl: data.frontUrl,
        backUrl: data.backUrl
      })
      .then(() => {
        alert('Documents Verified')
        window.location.href = '#/all-students/'
      })
      .catch(e => alert(e.message))
  }

  return (
    <article>
      <Container className='px-0'>
        <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
          <Col className='d-block mb-4 mb-md-0'>
            <h1 className='h2'>Student Information</h1>
            <p className='mb-0'>
              Here you can view a students details <a href={'#/all-students/'}>Back to Students</a>
            </p>
          </Col>
        </Row>

        <Form onSubmit={UpdateStudent}>
          <Form.Group className='mb-3'>
            <Form.Label>
              Student Name : {data.name} | {data._id}{' '}
            </Form.Label>
            <br />
            <Form.Label>
              Student Info : Address : {data.address} | City : {data.city} | State: {data.state} | Country :{' '}
              {data.country}{' '}
            </Form.Label>

            <br />
            <Form.Label>
              Student Contact : {data.email} | Phone : {data.phone}
            </Form.Label>
            <br />

            <Form.Label>Student Documents : {data.documentType} </Form.Label>
            <br />

            <Form.Label>
              Front Img:{' '}
              <a style={{ color: 'orangered' }} target='__blank' href={data.frontUrl}>
                See Documents
              </a>
            </Form.Label>
            <br />

            <Form.Label>
              Back Img:{' '}
              <a style={{ color: 'orangered' }} target='__blank' href={data.backUrl}>
                See Documents
              </a>
            </Form.Label>
            <br />

            <Form.Label style={{ color: data.verified === true ? 'green' : 'red' }}>
              Verified Status: {data.verified === true ? 'Verified' : 'Not Verified'}{' '}
            </Form.Label>
            <br />
          </Form.Group>

          {data.verified === true ? (
            <>Documents already verified</>
          ) : (
            <button type='submit' className='btn btn-info' style={{ marginTop: 12 }}>
              I verify these documents
            </button>
          )}
        </Form>
      </Container>
    </article>
  )
}
