/* eslint-disable react/no-deprecated */
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'

// core styles
import './scss/volt.css'

// vendor styles
import '@fortawesome/fontawesome-free/css/all.css'
import 'react-datetime/css/react-datetime.css'

import HomePage from './pages/HomePage'
import ScrollToTop from './components/ScrollToTop'
import LoginBoard from './pages/LoginBoard'
// import Preloader from './components/Preloader'
import axios from 'axios'
import CoachBoard from './pages/CoachBoard'

import { UserProvider } from './context/MyContext'
axios.defaults.baseURL = process.env.REACT_APP_API_KEY

const Public = () => {
  return (
    <HashRouter>
      <ScrollToTop />
      <LoginBoard />
    </HashRouter>
  )
}
const Coach = () => {
  return (
    <HashRouter>
      <ScrollToTop />
      <CoachBoard />
    </HashRouter>
  )
}

const User = () => {
  return (
    <HashRouter>
      <ScrollToTop />
      <HomePage />
    </HashRouter>
  )
}

const App = () => {
  const userData = localStorage.getItem('loginData')
  const userParseData = JSON.parse(userData)
  const [localData] = useState(userParseData)

  if (localData?.role === 'admin') return <User />
  if (localData?.role === 'super-admin') return <User />
  if (localData?.role === 'staff') return <Coach />
  return <Public />
}

const userData = JSON.parse(localStorage.getItem('loginData') || null)

ReactDOM.render(
  <UserProvider initialUser={userData}>
    <App />
  </UserProvider>,
  document.getElementById('root')
)
