/* eslint-disable react/react-in-jsx-scope */
import { Col, Row, Button, Card, Spinner } from '@themesberg/react-bootstrap'
import { callApi } from '../../utils/api-utils'
import { useEffect, useState } from 'react'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    }
  }
}

const viewEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/purchase/purchased-data-view/:id'
}

export default props => {
  const [viewData, setViewData] = useState({})
  const [loading, setLoading] = useState(true)

  const callApiFunc = async () => {
    await callApi({ uriEndPoint: viewEndPoint, pathParams: { id: props?.match?.params?.id || null } })
      .then(res => {
        setViewData(res?.data[0] || '')
        setLoading(false)
      })

      .catch(e => {
        console.log(e)
        setLoading(false)
      })
  }

  useEffect(() => {
    callApiFunc()
  }, [])

  return (
    <>
      <div>
        <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
          <Col className='d-block mb-4 mb-md-0'>
            <h1 className='h2'>View InApp Purchase User</h1>
            <p className='mb-0'>Here you can view inapp purchase User for SHAH</p>
          </Col>
        </Row>

        {loading ? (
          <Card>
            <Card.Body>
              <Spinner animation='border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            </Card.Body>
          </Card>
        ) : (
          <Card>
            <Card.Body>
              <Card.Title>Email: {viewData?.studentid?.email || ''}</Card.Title>
              <Card.Subtitle className='mb-2 text-muted'>Coins: {viewData?.inAppPurchaseid?.coin || ''}</Card.Subtitle>
              <Card.Body>
                <Card.Text>Product ID: {viewData?.productId || '' || ''}</Card.Text>
                <Card.Text>Phone: {viewData?.studentid?.phone || '' || ''}</Card.Text>
                <Card.Text>Transaction Id: {viewData?.transactionId || '' || ''}</Card.Text>
                <Card.Text>Transaction Receipt: {viewData?.transactionReceipt || '' || ''}</Card.Text>
              </Card.Body>
            </Card.Body>
          </Card>
        )}

        <div className='d-flex justify-content-end gap-3 mt-4'>
          <Button
            variant='info'
            type='button'
            onClick={() => {
              window.history.back()
            }}
          >
            Back
          </Button>
        </div>
      </div>
    </>
  )
}
