import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { QuizTable } from '../components/Tables'

export default props => {
  const chapterid = props.match.params.id
  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0'>
          {/* <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}> */}
          {/* <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item> */}
          {/* 
          </Breadcrumb> */}
          <h4 onClick={() => window.history.back()}>
            {' '}
            <FontAwesomeIcon icon={faArrowLeft} style={{ cursor: 'pointer' }} /> All Questions
          </h4>
          <p className='mb-0'>SHAH questions management.</p>
        </div>
      </div>

      <QuizTable chapterid={chapterid} />
    </>
  )
}
