import React, { useEffect, useState } from 'react'

import { Button, Card, Container, Table, Modal, Form, Row, Col } from '@themesberg/react-bootstrap'

import axios from 'axios'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import moment from 'moment'

export default () => {
  // const [status, setStatus] = useState([])

  const [eventName, setEventName] = useState('')
  const [paymentType, setPaymentType] = useState('free')
  const [eventDescription, setEventDescription] = useState('')
  const [eventURL, setEventURL] = useState('')
  const [eventDate, setEventDate] = useState('')
  const [meetingId, setMeetingId] = useState('')
  const [data, setData] = useState([])
  const [selectedTime, setSelectedTime] = useState('')
  const [recallApi, setRecallApi] = useState('')

  const [id, setId] = useState(null)

  const generateTimeOptions = () => {
    let times = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        // Format the time as "HH:MM"
        let formattedHour = hour.toString().padStart(2, '0')
        let formattedMinute = minute.toString().padStart(2, '0')
        times.push(`${formattedHour}:${formattedMinute}`)
      }
    }
    return times
  }

  // State to store selected time

  // Handle change in dropdown selection
  const handleTimeChange = event => {
    setSelectedTime(event.target.value)
  }

  // Generate time options array
  const timeOptions = generateTimeOptions()

  useEffect(() => {
    StatusWebinar()
  }, [recallApi])

  // Start Webinar

  async function StatusWebinar() {
    await axios
      .get('/api/webinar-create/getWebinar')
      .then(res => {
        // setStatus(res.data.data)
        setData(res.data.data)
      })
      .catch(e => alert(e))
  }

  // async function StartWebinar() {
  //   await axios
  //     .get('/api/webinar-create/getWebinar')
  //     .then(res => {
  //       setStatus(res.data)
  //     })
  //     .catch(e => alert(e))
  // }

  // async function StopWebinar() {
  //   await axios
  //     .get('/api/webinar-create/stop')
  //     .then(res => setStatus(res.data))
  //     .catch(e => alert(e))
  // }

  async function FormSubmit(e) {
    e.preventDefault()
    const body = {
      eventType: paymentType,
      meetingId: meetingId,
      eventName: eventName,
      eventDescription: eventDescription,
      eventDate: eventDate,
      eventURL: eventURL,
      eventTime: selectedTime
    }

    try {
      await axios
        .post('/api/webinar-create/create-webinar', body)
        .then(() => {
          // setStatus([res.data])
          setRecallApi(Math.floor(Math.random() * 1000).toString())
          setEventName('')
          setEventDescription('')
          setEventURL('')
          setEventDate('')
          setMeetingId('')
          setSelectedTime('')
          handleClose()
        })
        .catch(e => alert(e))
    } catch (error) {
      console.log(`🚀  ~ file: Webinar.js:55 ~ FormSubmit ~ error:`, error)
    }
  }

  async function DeleteWebinar() {
    await axios
      .get(`/api/webinar-create/delete/${id}`)
      .then(() => setRecallApi(Math.floor(Math.random() * 1000).toString()))
      .catch(e => alert(e))
  }

  const TableRow = props => {
    const { _id, eventType, eventName, meetingId, eventDescription, eventTime, index, eventDate } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal' style={{ textTransform: 'capitalize' }}>
            {eventType}
          </span>
        </td>
        <td>
          <span className='fw-normal'>{meetingId || '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{eventName}</span>
        </td>
        <td>
          <span className='fw-normal'>{eventDescription}</span>
        </td>
        <td>
          <span className='fw-normal'>{eventDate ? moment(eventDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{eventTime}</span>
        </td>

        <td style={{ display: 'flex', gap: 10, flexDirection: 'row' }}>
          {/* <Button style={{ cursor: 'pointer' }} onClick={() => (window.location.href = `#/view-free-webinar/` + _id)}>
              view
            </Button> */}
          <Button
            style={{ backgroundColor: 'red' }}
            data-toggle='modal'
            data-target='#exampleModalCenter-webinar'
            onClick={() => setId(_id)}
          >
            delete
          </Button>
        </td>
      </tr>
    )
  }
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <article>
      <Container className='px-0'>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <div>
            <h1 className='h2'>SHAH Webinar</h1>
            <Button variant='primary' onClick={handleShow}>
              Create Webinar
            </Button>
          </div>
          <Card border='light' className='table-wrapper table-responsive shadow-sm'>
            <Card.Body style={{ height: 432 }} className='pt-0'>
              <Table hover className='user-table align-items-center'>
                <thead>
                  <tr>
                    <th
                      className='border-bottom'
                      style={{
                        position: 'sticky',
                        top: 0,
                        background: 'white',
                        zIndex: 1,
                        borderBottom: '2px solid #dee2e6'
                      }}
                    >
                      No.
                    </th>
                    <th
                      className='border-bottom'
                      style={{
                        position: 'sticky',
                        top: 0,
                        background: 'white',
                        zIndex: 1,
                        borderBottom: '2px solid #dee2e6'
                      }}
                    >
                      Event Type
                    </th>
                    <th
                      className='border-bottom'
                      style={{
                        position: 'sticky',
                        top: 0,
                        background: 'white',
                        zIndex: 1,
                        borderBottom: '2px solid #dee2e6'
                      }}
                    >
                      Event Name
                    </th>
                    <th
                      className='border-bottom'
                      style={{
                        position: 'sticky',
                        top: 0,
                        background: 'white',
                        zIndex: 1,
                        borderBottom: '2px solid #dee2e6'
                      }}
                    >
                      Description
                    </th>
                    <th
                      className='border-bottom'
                      style={{
                        position: 'sticky',
                        top: 0,
                        background: 'white',
                        zIndex: 1,
                        borderBottom: '2px solid #dee2e6'
                      }}
                    >
                      Event Date
                    </th>
                    <th
                      className='border-bottom'
                      style={{
                        position: 'sticky',
                        top: 0,
                        background: 'white',
                        zIndex: 1,
                        borderBottom: '2px solid #dee2e6'
                      }}
                    >
                      Time
                    </th>

                    <th
                      className='border-bottom'
                      style={{
                        position: 'sticky',
                        top: 0,
                        background: 'white',
                        zIndex: 1,
                        borderBottom: '2px solid #dee2e6'
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((t, index) => {
                    return <TableRow key={`transaction-${t._id}`} {...t} index={index} />
                  })}
                </tbody>
              </Table>
              <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
            </Card.Body>
          </Card>
          <div
            className='modal fade'
            id='exampleModalCenter-webinar'
            tabIndex='-1'
            role='dialog'
            aria-labelledby='exampleModalCenterTitle'
            aria-hidden='true'
          >
            <div className='modal-dialog modal-dialog-centered' role='document'>
              <div className='modal-content'>
                <div className='modal-header  w-100 justify-content-center'>
                  <h5 className='modal-title' id='exampleModalLongTitle'>
                    Are you sure?
                  </h5>
                </div>
                <div className='modal-body align-self-center'>You won't be able to revert this!</div>
                <div className='modal-footer justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    data-dismiss='modal'
                    onClick={() => DeleteWebinar()}
                  >
                    Yes, delete it!
                  </button>
                  <button type='button' className='btn btn-primary' data-dismiss='modal'>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={show} onHide={handleClose} backdrop='static' size='lg' centered>
          <Modal.Header closeButton>
            <Modal.Title>Create Webinar</Modal.Title>
          </Modal.Header>
          <form id='webinar' onSubmit={FormSubmit}>
            <Modal.Body>
              <Container>
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label>Webinar Type</Form.Label>
                      <Form.Select value={paymentType} size='sm' onChange={e => setPaymentType(e.target.value)}>
                        <option value={'free'}>Free Webinar</option>
                        <option value={'paid'}>Paid Webinar</option>
                      </Form.Select>{' '}
                    </Form.Group>
                  </Col>
                  <Col>
                    {paymentType === 'paid' && (
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label htmlFor='meetingId'>Meeting ID</Form.Label>
                        <Form.Control
                          size='sm'
                          id='meetingId'
                          value={meetingId}
                          onChange={e => setMeetingId(e.target.value)}
                          aria-describedby='passwordHelpBlock'
                        />
                      </Form.Group>
                    )}
                  </Col>
                </Row>{' '}
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label htmlFor='eventName'>Event Name</Form.Label>
                      <Form.Control
                        size='sm'
                        id='eventName'
                        required
                        value={eventName}
                        onChange={e => setEventName(e.target.value)}
                        aria-describedby='passwordHelpBlock'
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label htmlFor='eventDescription'>Event Description</Form.Label>
                      <Form.Control
                        size='sm'
                        id='eventDescription'
                        required
                        value={eventDescription}
                        onChange={e => setEventDescription(e.target.value)}
                        aria-describedby='passwordHelpBlock'
                      />
                    </Form.Group>
                  </Col>
                </Row>{' '}
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label htmlFor='webinarURL'>Webinar URL</Form.Label>
                      <Form.Control
                        size='sm'
                        id='webinarURL'
                        required
                        value={eventURL}
                        onChange={e => setEventURL(e.target.value)}
                        aria-describedby='passwordHelpBlock'
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label htmlFor='date'>Date</Form.Label>
                      <Form.Control
                        size='sm'
                        type='date'
                        id='date'
                        required
                        value={eventDate}
                        onChange={e => setEventDate(e.target.value)}
                        aria-describedby='passwordHelpBlock'
                      />
                    </Form.Group>
                  </Col>
                </Row>{' '}
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label>Select Webinar Time</Form.Label>
                      <Form.Select required size='sm' value={selectedTime} onChange={handleTimeChange}>
                        <option value=''>--Please choose time--</option>
                        {timeOptions.map((time, index) => {
                          return (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          )
                        })}
                      </Form.Select>{' '}
                    </Form.Group>
                  </Col>
                </Row>{' '}
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
              <Button variant='primary' type='submit'>
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </Container>
    </article>
  )
}
