import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Breadcrumb } from '@themesberg/react-bootstrap'
import { InAppPurchaseListTable } from '../components/Tables'
export const InAppPurchase = () => {
  return (
    <div>
      {' '}
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All InApp Purchase Code</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All InApp Purchase Code</h4>
          <div className='d-flex justify-content-between  align-items-center'>
            <p className='mb-0'>SHAH InApp Purchase Code Management.</p>
          </div>
        </div>
      </div>
      <div className='py-2 text-end'>
        <button className='btn btn-info'>
          <a className='text-white' href='#/create-in-app-purchase/'>
            Add InApp Purchase
          </a>
        </button>
      </div>
      <InAppPurchaseListTable />
    </div>
  )
}
