import React, { useState, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Routes } from '../routes'

// pages
import Signin from './examples/Signin'
import ServerError from './examples/ServerError'

// // components
// import Sidebar from '../components/Sidebar'
// import Navbar from '../components/Navbar'
// import Footer from '../components/Footer'
import Preloader from '../components/Preloader'

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Route
      {...rest}
      render={props => (
        <>
          {' '}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{' '}
        </>
      )}
    />
  )
}

export default () => (
  <Switch>
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.ErrorM.path} component={ServerError} />

    <Redirect to={Routes.ErrorM.path} />
  </Switch>
)
