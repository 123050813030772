import React, { useState, useEffect } from 'react'
import { Col, Row, Form, Container } from '@themesberg/react-bootstrap'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
// import htmlToDraft from 'html-to-draftjs'
import {
  EditorState,
  convertToRaw
  // ContentState
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'

import axios from 'axios'

const FreeEditQuiz = props => {
  const [id] = useState(props.match.params.id)
  const [id2] = useState(props.match.params.id2)

  const [nameOfCourse, setnameOfCourse] = useState('')
  const [quizType, setQuizType] = useState(false)
  const [Img, setImg] = useState('')
  const [RegularPrice, setRegularPrice] = useState('')
  const [SellingPrice, setSellingPrice] = useState('')
  const [tutorName, setTutorName] = useState('')
  const [tutorImg, setTutorImg] = useState('')
  const [tutorBio, setTutorBio] = useState('')
  const [isPaid, setIsPaid] = useState('')
  const [quizNumber, setQuizNumber] = useState(null)

  const [
    ,
    // find
    setFind
  ] = useState(false)

  const [questions, setQuestions] = useState([])

  const [
    editorState
    //  setEditorState
  ] = useState(() => EditorState.createEmpty())

  // const handleEditorChange = state => {
  //   setEditorState(state)
  // }

  function RecieveData(html) {
    const data = '' + html + ''
    console.log(data)

    //  if (data) {
    //   const contentState = ContentState.createFromBlockArray(
    //     data.contentBlocks
    //   )
    //   const editorState = EditorState.createWithContent(contentState);
    //   setEditorState(editorState);

    // }
  }

  function CourseCreation(e) {
    e.preventDefault()
    FindData(id)

    // convertContentToHTML()
    // alert('Quiz Created')
  }

  async function FindData(ids) {
    alert('Updating...')
    await axios
      .get(`/api/free-lesson-quiz/view/${ids}`)
      .then(() => {
        setFind(true)
      })
      .catch(() => {
        setFind(false)
        convertContentToHTML()
      })
  }

  // LoadData()

  // Create Questions and quiz
  // Update Questions
  // Delete Questions
  // Delete Quiz

  useEffect(() => {
    LoadQuiz(id)
  }, [])

  async function addNextQuestion() {
    if (questions.length === 0) {
      return null
    } else {
      const q = questions
      const index = q.findIndex(i => i._id === id2)
      q[index].question = Img
      q[index].answer = RegularPrice
      q[index].option1 = quizType === true ? 'True' : SellingPrice
      q[index].option2 = quizType === true ? 'False' : tutorName
      q[index].option3 = tutorImg
      q[index].option4 = tutorBio
      q[index].type = quizType

      let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      await axios
        .put(`/api/free-lesson-quiz/update/${id}`, {
          free_lesson_id: id,
          nameOfCourse: nameOfCourse,
          description: currentContentAsHTML,
          time: isPaid,
          showQuiz: quizNumber,
          questions: q
        })
        .then(() => {
          alert('Question is updated')
          window.location.href = `#/free-course/all-quiz/` + id
        })
        .catch(e => alert(e))
    }
  }

  // function RecieveData(html) {
  //   const contentBlock = htmlToDraft(html)
  //   if (contentBlock) {
  //     const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  //     const editorState = EditorState.createWithContent(contentState)
  //     setEditorState(editorState)
  //   }
  // }

  async function LoadQuiz(ids) {
    await axios
      .get(`/api/free-lesson-quiz/view/${ids}`)
      .then(res => {
        setnameOfCourse(res.data?.data?.nameOfCourse)
        setQuizNumber(res.data?.data?.showQuiz)
        setIsPaid(res.data?.data?.time)
        setQuestions(res.data?.data?.questions)
        const filter = res.data?.data?.questions.filter(r => r._id === id2)
        console.log(filter[0].question)

        setImg(filter[0].question)
        setRegularPrice(filter[0].answer)
        setQuizType(filter[0].type)
        setSellingPrice(filter[0].type === true ? 'True' : filter[0].option1)
        setTutorName(filter[0].type === true ? 'False' : filter[0].option2)
        setTutorImg(filter[0].option3)
        setTutorBio(filter[0].option4)
        RecieveData(res.data?.data?.description)
      })
      .catch(err => console.log(err))
  }

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    const dataQuestion = []

    dataQuestion.push({
      question: Img,
      answer: RegularPrice,
      option1: quizType === true ? 'True' : SellingPrice,
      option2: quizType === true ? 'False' : tutorName,
      option3: tutorImg,
      option4: tutorBio,
      type: quizType
    })

    axios
      .post('/api/free-lesson-quiz/add', {
        chapterid: id,
        nameOfCourse: nameOfCourse,
        description: currentContentAsHTML,
        time: isPaid,

        questions: dataQuestion
      })
      .then(() => {
        alert('Quiz Created')
        window.location.reload()
      })
      .catch(e => alert(e))
  }

  return (
    <article>
      <Container className='px-0'>
        <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
          <Col className='d-block mb-4 mb-md-0'>
            <h1 className='h2'>Edit Question</h1>
            <p className='mb-0'>Here you can edit questions for SHAH</p>
          </Col>
        </Row>
        <Form onSubmit={CourseCreation}>
          <Form.Group className='mb-3'>
            <Form.Group style={{ marginTop: 12 }} className='mb-3'>
              <Form.Label>Create Question</Form.Label>
              <Form.Control
                as='textarea'
                rows={2}
                value={Img}
                onChange={e => setImg(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Create Question'
              />

              <Form.Label style={{ marginTop: 12 }}>Choose question type</Form.Label>
              <br />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div
                  onClick={() => {
                    quizType === false ? setQuizType(true) : setQuizType(true)
                  }}
                  style={{ marginLeft: 5, marginRight: 5, cursor: 'pointer' }}
                >
                  {' '}
                  <input checked={quizType} type='checkbox' /> True/ False
                </div>
                <div
                  onClick={() => {
                    quizType === true
                      ? setQuizType(false)
                      : setQuizType(false) && setSellingPrice('') && setTutorName('')
                  }}
                  style={{ marginLeft: 5, marginRight: 5, cursor: 'pointer' }}
                >
                  <input checked={quizType === false ? true : false} type='checkbox' /> MCQs
                </div>
              </div>

              <Form.Label style={{ marginTop: 12 }}>Create Answer</Form.Label>
              <Form.Control
                value={RegularPrice}
                onChange={e => setRegularPrice(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Write your answer'
              />

              <Form.Label style={{ marginTop: 12 }}>Create Options</Form.Label>
              <Form.Control
                value={quizType === true ? 'True' : SellingPrice}
                onChange={e => setSellingPrice(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder={quizType === true ? 'True' : 'Option 1'}
              />
              <Form.Control
                value={quizType === true ? 'False' : tutorName}
                onChange={e => setTutorName(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder={quizType === true ? 'False' : 'Option 2'}
              />

              {quizType === false ? (
                <>
                  <Form.Control
                    value={tutorImg}
                    onChange={e => setTutorImg(e.target.value)}
                    style={{ marginTop: 4 }}
                    type='text'
                    placeholder='Option 3'
                  />
                  <Form.Control
                    value={tutorBio}
                    onChange={e => setTutorBio(e.target.value)}
                    style={{ marginTop: 4 }}
                    type='text'
                    placeholder='Option 4'
                  />
                </>
              ) : (
                <></>
              )}
            </Form.Group>

            <button onClick={() => addNextQuestion()} style={{ cursor: 'pointer' }} className='btn btn-danger'>
              {' '}
              Save Question{' '}
            </button>
          </Form.Group>
        </Form>
      </Container>
    </article>
  )
}

export default FreeEditQuiz
