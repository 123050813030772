// UserContext.js
import { createContext, useContext, useState } from 'react'

const UserContext = createContext()

export const useUserContext = () => {
  return useContext(UserContext)
}

export const UserProvider = ({ children, initialUser }) => {
  const [user, setUser] = useState(initialUser) // Initialize with your user data

  const login = userData => {
    // Implement your login logic here and set the user data
    setUser(userData)
  }

  const logout = () => {
    // Implement your logout logic here
    setUser(null)
  }

  // eslint-disable-next-line react/react-in-jsx-scope
  return <UserContext.Provider value={{ user, login, logout }}>{children}</UserContext.Provider>
}
