import React from 'react'
import { Col, Row, Container } from '@themesberg/react-bootstrap'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export default () => {
  return (
    <article>
      <Container className='px-0'>
        <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
          <Col className='d-block mb-4 mb-md-0'>
            <h1 className='h2'>Coming Soon</h1>
            <p className='mb-0'>This feature is avaliable soon</p>
          </Col>
        </Row>
      </Container>
    </article>
  )
}
