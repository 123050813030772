import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Breadcrumb } from '@themesberg/react-bootstrap'

import { QuizFormTable } from '../components/Tables'

export default () => {
  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>Quiz Score</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Quiz Score</h4>
          <div className='d-flex justify-content-between  align-items-center'>
            <p className='mb-0'>Quiz Score Management.</p>
          </div>
        </div>
      </div>

      <QuizFormTable />
    </>
  )
}
