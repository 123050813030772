import React, { useState, useEffect } from 'react'
import Chartist from 'react-chartist'

import { callApi } from '../utils/api-utils'
import moment from 'moment-timezone'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}
const orderListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/orders/total-orders'
}

export const SalesValueChart = () => {
  const [mon, setMon] = useState([])
  const [tue, setTue] = useState([])
  const [wed, setWed] = useState([])
  const [thr, setThr] = useState([])
  const [fri, setFri] = useState([])
  const [sat, setSat] = useState([])
  const [sun, setSun] = useState([])

  useEffect(() => {
    GetOrderData()
  }, [])

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const d = new Date()

  async function GetOrderData() {
    await callApi({ uriEndPoint: orderListEndPoint }).then(res => {
      if (res?.orders) {
        const tempDoc = res.orders
        const inr_user = tempDoc.filter(e => e.category === 'homeopaths' || e.category === 'indian')
        const newAmountArray = []

        for (let i = 0; i < tempDoc?.length; i++) {
          const element = tempDoc[i]
          if (
            (element.studentname === 'ketan' || element.studentname === 'ketanpatel') &&
            (element.category === 'indian' || element.category === 'homeopaths')
          ) {
            newAmountArray.push(element)
          }
        }

        const inr_users = inr_user.filter(e => moment(e.date).format('DD-MMM-YYYY').includes(monthNames[d.getMonth()]))

        DataArrange(inr_users)
      }
    })
  }

  function DataArrange(d) {
    const orders = d

    const Monday = orders.filter(r => moment(r.date).format('ddd').includes('Mon'))
    const Tuesday = orders.filter(r => moment(r.date).format('ddd').includes('Tue'))
    const Wednesday = orders.filter(r => moment(r.date).format('ddd').includes('Wed'))
    const Thursday = orders.filter(r => moment(r.date).format('ddd').includes('Thu'))
    const Friday = orders.filter(r => moment(r.date).format('ddd').includes('Fri'))
    const Saturday = orders.filter(r => moment(r.date).format('ddd').includes('Sat'))
    const Sunday = orders.filter(r => moment(r.date).format('ddd').includes('Sun'))

    setMon(Monday)
    setTue(Tuesday)
    setWed(Wednesday)
    setThr(Thursday)
    setFri(Friday)
    setSat(Saturday)
    setSun(Sunday)
  }

  const data = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    series: [[mon.length, tue.length, wed.length, thr.length, fri.length, sat.length, sun.length]]
  }

  const options = {
    low: 0,
    showArea: true,
    fullWidth: true,
    axisX: {
      position: 'end',
      showGrid: true
    },
    axisY: {
      // On the y-axis start means left and end means right
      showGrid: false,
      showLabel: false,
      labelInterpolationFnc: value => `$${value / 1}k`
    }
  }

  const type = 'Line'

  return <Chartist data={data} options={options} type={type} className='ct-series-g ct-double-octave' />
}
export const SalesValueChartUsd = () => {
  const [mon, setMon] = useState([])
  const [tue, setTue] = useState([])
  const [wed, setWed] = useState([])
  const [thr, setThr] = useState([])
  const [fri, setFri] = useState([])
  const [sat, setSat] = useState([])
  const [sun, setSun] = useState([])

  useEffect(() => {
    GetOrderData()
  }, [])

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const d = new Date()

  async function GetOrderData() {
    await callApi({ uriEndPoint: orderListEndPoint }).then(res => {
      if (res?.orders) {
        const tempDoc = res.orders

        const pro_user = tempDoc.filter(e => e.category === 'international')
        const newAmountArray = []

        for (let i = 0; i < tempDoc?.length; i++) {
          const element = tempDoc[i]
          if (
            (element.studentname !== 'ketan' || element.studentname !== 'ketanpatel') &&
            element.category === 'international'
          ) {
            newAmountArray.push(element)
            return null
          }
        }

        const usd_users = pro_user.filter(e => moment(e.date).format('DD-MMM-YYYY').includes(monthNames[d.getMonth()]))

        DataArrange(usd_users)
      }
    })
  }

  function DataArrange(d) {
    const orders = d

    const Monday = orders.filter(r => moment(r.date).format('ddd').includes('Mon'))
    const Tuesday = orders.filter(r => moment(r.date).format('ddd').includes('Tue'))
    const Wednesday = orders.filter(r => moment(r.date).format('ddd').includes('Wed'))
    const Thursday = orders.filter(r => moment(r.date).format('ddd').includes('Thu'))
    const Friday = orders.filter(r => moment(r.date).format('ddd').includes('Fri'))
    const Saturday = orders.filter(r => moment(r.date).format('ddd').includes('Sat'))
    const Sunday = orders.filter(r => moment(r.date).format('ddd').includes('Sun'))

    setMon(Monday)
    setTue(Tuesday)
    setWed(Wednesday)
    setThr(Thursday)
    setFri(Friday)
    setSat(Saturday)
    setSun(Sunday)
  }

  const data = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    series: [[mon.length, tue.length, wed.length, thr.length, fri.length, sat.length, sun.length]]
  }

  const options = {
    low: 0,
    showArea: true,
    fullWidth: true,
    axisX: {
      position: 'end',
      showGrid: true
    },
    axisY: {
      // On the y-axis start means left and end means right
      showGrid: false,
      showLabel: false,
      labelInterpolationFnc: value => `$${value / 1}k`
    }
  }

  const type = 'Line'

  return <Chartist data={data} options={options} type={type} className='ct-series-g ct-double-octave' />
}

export const SalesValueChartphone = () => {
  const data = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    series: [[1, 2, 2, 3, 3, 4, 3]]
  }

  const options = {
    low: 0,
    showArea: true,
    fullWidth: false,
    axisX: {
      position: 'end',
      showGrid: true
    },
    axisY: {
      // On the y-axis start means left and end means right
      showGrid: false,
      showLabel: false,
      labelInterpolationFnc: value => `$${value / 1}k`
    }
  }

  const type = 'Line'
  return <Chartist data={data} options={options} type={type} className='ct-series-g ct-major-tenth' />
}

export const CircleChart = props => {
  const { series = [], donutWidth = 20 } = props
  const sum = (a, b) => a + b

  const options = {
    low: 0,
    high: 8,
    donutWidth,
    donut: true,
    donutSolid: true,
    fullWidth: false,
    showLabel: false,
    labelInterpolationFnc: value => `${Math.round((value / series.reduce(sum)) * 100)}%`
  }

  const type = 'Pie'
  return <Chartist data={series} options={options} type={type} className='ct-golden-section' />
}

export const BarChart = props => {
  const { labels = [], series = [], chartClassName = 'ct-golden-section' } = props
  const data = { labels, series }

  const options = {
    low: 0,
    showArea: true,
    axisX: {
      position: 'end'
    },
    axisY: {
      showGrid: false,
      showLabel: false,
      offset: 0
    }
  }

  const type = 'Bar'
  return <Chartist data={data} options={options} type={type} className={chartClassName} />
}
