import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Breadcrumb, Button, Col, Form, Row } from '@themesberg/react-bootstrap'

import { TransactionReportListTable } from '../components/Tables'
import { callApi } from '../utils/api-utils'
import { CSVLink } from 'react-csv'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const userReportListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/orders/orders-csv'
}
const courseList = {
  ...defaults.methods.GET,
  uri: '/api/course?type=front-end-api'
}

export default () => {
  const [selectStudent, setSelectStudent] = useState('')
  const [transactionData, setTransactionData] = useState([])
  const [AllCourse, setAllCourse] = useState([])
  const [selectCourseId, setSelectCourseId] = useState('')

  const [selectCourse, setSelectCourse] = useState('')

  const clickToCallApi = async () => {
    await callApi({
      uriEndPoint: userReportListEndPoint,
      query: { student_select: selectStudent, courseid: selectCourseId }
    })
      .then(res => {
        if (res?.data) {
          const newVAlue = res?.data

          const mapData = newVAlue.map(i => {
            delete i._id

            for (let x in i) {
              if (!i[x]) {
                i[x] = '-'
              }
            }
            return i
          })
          setTransactionData(mapData)
        } else {
          setTransactionData([])
        }
      })
      .catch(e => console.log(e))
    if (!selectStudent.length) {
      setSelectStudent(true)
    }
    if (!selectCourse.length) {
      setSelectCourse(true)
    }
  }
  async function Refresh() {
    await callApi({ uriEndPoint: courseList })
      .then(res => {
        const data = res.courses

        setAllCourse(data)
      })
      .catch(e => alert(e))
  }

  useEffect(() => {
    Refresh()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Transaction Report</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Transaction Report</h4>
          <div className='d-flex justify-content-between  align-items-center'>
            <p className='mb-0'>SHAH Transaction Report Management.</p>
          </div>
        </div>
      </div>

      <Row style={{ gap: '10px', margin: '1rem 0px' }}>
        <Col xl={4} style={{ padding: '0px' }}>
          <Form.Select aria-label='Default select example' onChange={e => setSelectStudent(e.target.value)}>
            <option>Open this select student</option>
            <option value='indian_students'>Indian Students</option>
            <option value='indian_homeopathic_practitioners'>Indian Homeopathic Practitioners</option>
            <option value='international_homeopathic_students'>International Homeopathic Students</option>
          </Form.Select>
          {selectStudent === true && <div style={{ color: 'red', fontSize: '12px' }}>Please Select Student</div>}
        </Col>
        <Col xl={4} style={{ padding: '0px' }}>
          <Form.Select aria-label='Default select example' onChange={e => setSelectCourseId(e.target.value)}>
            <option>Open this select Course</option>
            {AllCourse?.map(i => {
              return (
                <option value={i._id} key={i._id}>
                  {i.nameOfCourse}
                </option>
              )
            })}
          </Form.Select>
        </Col>
        <Col xl={3} style={{ display: 'flex', gap: '10px', padding: '0' }}>
          <Button onClick={() => clickToCallApi()}>Search</Button>
          {transactionData.length ? (
            <CSVLink data={transactionData}>
              <Button>Export</Button>
            </CSVLink>
          ) : null}
        </Col>
      </Row>
      <TransactionReportListTable transactionData={transactionData} />
    </>
  )
}
