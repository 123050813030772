import React, { useState, useEffect } from 'react'
import { Col, Row, Form, Container } from '@themesberg/react-bootstrap'

import axios from 'axios'

export default props => {
  const [fireid] = useState(props.match.params.id)
  const [data, setData] = useState([])

  useEffect(() => {
    UserData()
  }, [fireid])

  async function UserData() {
    await axios
      .get(`/api/scholarShip/getScholarShips/${fireid}`)
      .then(res => setData(res.data.data))
      .catch(e => alert(e))
  }

  return (
    <article>
      <Container className='px-0'>
        <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
          <Col className='d-block mb-4 mb-md-0'>
            <h1 className='h2'>Student Scholarship</h1>
            <p className='mb-0'>
              Here you can view a Scholarship details <a href={'#/scholarship/'}>Back to Scholarship</a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className='d-block mb-6 mb-md-0' xs={6}>
            {' '}
            <Form.Label>Student Name : {data.name}</Form.Label>
            <br />
            <Form.Label>
              Student Info : Address : {data.permanent_address}
              {data.country}{' '}
            </Form.Label>
            <br />
            <Form.Label>
              Student Contact : {data.email} | Phone : {data.phone}
            </Form.Label>
            <br />
            <Form.Label>Alternative Phone : {data.altPhone}</Form.Label>
            <br />
            <Form.Label>BirthDate : {data.birth_date}</Form.Label>
            <br />
            <Form.Label>Gender : {data.gender}</Form.Label>
            <br />
          </Col>
          <Col className='d-block mb-6 mb-md-0' xs={6}>
            {' '}
            <Form.Label>College: {data.college}</Form.Label>
            <br />
            <Form.Label>College No: {data.clg_phone}</Form.Label>
            <br />
            <Form.Label>College City: {data.college_city}</Form.Label>
            <br />
            <Form.Label>College State: {data.college_state}</Form.Label>
            <br />
            <Form.Label>Course: {data.course}</Form.Label>
            <br />
          </Col>
        </Row>
        <button
          className='btn btn-info'
          style={{ marginTop: 12 }}
          onClick={() => (window.location.href = '#/scholarship')}
        >
          Back
        </button>
      </Container>
    </article>
  )
}
