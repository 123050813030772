import React, { useEffect, useState } from 'react'
import { callApi } from '../../../utils/api-utils'
import { Form } from '@themesberg/react-bootstrap'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const editEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/coordinator/update/:id'
}
const viewEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/coordinator/view/:id'
}

const EditCoordinator = props => {
  const [addFiled, setAddFiled] = useState({})

  const [validationErrors, setValidationErrors] = useState({
    email: '',
    phone: ''
  })

  const validateEmail = email => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(email)
  }

  const validatePhone = phone => {
    const phoneRegex = /^\+?\d{1,2}[-\s]?\(?\d{1,4}\)?[-\s]?\d{1,4}[-\s]?\d{1,4}$/
    return phoneRegex.test(phone)
  }

  const handleSubmit = async e => {
    // Validation checks
    let errors = {
      email: '',
      phone: '',
      password: ''
    }
    if (!validateEmail(addFiled.email)) {
      errors.email = 'Invalid email format'
    }
    if (!validatePhone(addFiled.phone)) {
      errors.phone = 'Invalid phone number format'
    }

    setValidationErrors(errors)

    e.preventDefault()
    if (!errors.email && !errors.phone && !errors.password) {
      await callApi({ uriEndPoint: editEndPoint, body: addFiled, pathParams: { id: props?.match?.params?.id || null } })
        .then(res => {
          alert(res?.message || 'Update university successfully')
          window.history.back()
        })
        .catch(e => console.log(e))
    }
  }

  const handelChange = e => {
    const { name, value } = e.target

    // Update form field value
    setAddFiled({ ...addFiled, [name]: value })

    // Validate input dynamically on change
    let newValidationErrors = { ...validationErrors }

    // Check each field if it's being changed and validate accordingly
    if (name === 'email') {
      newValidationErrors.email = validateEmail(value) ? '' : 'Invalid email format'
    }
    if (name === 'phone') {
      newValidationErrors.phone = validatePhone(value) ? '' : 'Invalid phone number format'
    }

    setValidationErrors(newValidationErrors)
  }

  const handleStatusChange = e => {
    setAddFiled({ ...addFiled, status: e.target.checked })
  }

  const callApiFunc = async () => {
    await callApi({ uriEndPoint: viewEndPoint, pathParams: { id: props?.match?.params?.id || null } })
      .then(res => {
        setAddFiled(res?.data || '')
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    callApiFunc()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Edit University</h4>
            <button className='btn btn-primary' onClick={() => window.history.back()}>
              Back
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4'>Name *</label>
                <input
                  type='text'
                  name='name'
                  value={addFiled?.name || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  placeholder='Name'
                  maxLength={150}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4'>University Id</label>
                <input
                  type='text'
                  name='university_id'
                  value={addFiled?.university_id || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  placeholder='university id'
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4'>Email *</label>
                <input
                  type='email'
                  name='email'
                  value={addFiled?.email || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  maxLength={60}
                  placeholder='name@company.com'
                />
                {validationErrors.email && <small style={{ color: 'red' }}>{validationErrors.email}</small>}
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4'>Phone number *</label>
                <input
                  type='text'
                  name='phone'
                  value={addFiled?.phone || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  maxLength={13}
                  placeholder='+12-345 678 910'
                />
                {validationErrors.phone && <small style={{ color: 'red' }}>{validationErrors.phone}</small>}
              </div>

              <div className='form-group col-md-6'>
                <label htmlFor='inputState'>Status</label>
                <Form.Check // prettier-ignore
                  type='switch'
                  id='custom-switch'
                  data-toggle='modal'
                  data-target='#exampleModalCenterStatus'
                  checked={addFiled.status}
                  onChange={handleStatusChange}
                />
              </div>
            </div>

            <button type='submit' className='btn btn-primary mt-2'>
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default EditCoordinator
