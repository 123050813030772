/* eslint-disable no-unused-vars */
import { Col, Row, Button, Card, Spinner } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'
import { callApi } from '../../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const inAppPurchaseView = {
  ...defaults.methods.GET,
  uri: '/api/purchase/purchase-id-view/:id'
}
export const ViewInAppPurchase = props => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({ coins: '', product_id: '' })

  const callApiData = async () => {
    await callApi({ uriEndPoint: inAppPurchaseView, pathParams: { id: props?.match?.params?.id || null } })
      .then(res => {
        if (res) {
          setData({ coins: res?.coin || 'Your coins data', product_id: res?.product_id || 'Your product id data' })
          setLoading(false)
        }
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    // Simulating data fetching
    callApiData()
  }, [])

  return (
    <div>
      <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
        <Col className='d-block mb-4 mb-md-0'>
          <h1 className='h2'>View InApp Purchase</h1>
          <p className='mb-0'>Here you can view inapp purchase for SHAH</p>
        </Col>
      </Row>

      {loading ? (
        <Card>
          <Card.Body>
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </Card.Body>
        </Card>
      ) : (
        <Card>
          <Card.Body>
            <Card.Title>Coins: {data.coins}</Card.Title>
            <Card.Subtitle className='mb-2 text-muted'>Product ID: {data.product_id}</Card.Subtitle>
          </Card.Body>
        </Card>
      )}

      <div className='d-flex justify-content-end gap-3 mt-4'>
        <Button
          variant='info'
          type='button'
          onClick={() => {
            window.history.back()
          }}
        >
          Back
        </Button>
      </div>
    </div>
  )
}
