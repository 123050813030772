import { Col, Form, Row, Button } from '@themesberg/react-bootstrap'
import React, { useState } from 'react'
import { callApi } from '../../utils/api-utils'
const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const inAppPurchaseAdd = {
  ...defaults.methods.POST,
  uri: '/api/purchase/purchase-id-add'
}
export const CrateInAppPurchaseCode = () => {
  const [value, setValue] = useState({
    coin: '',
    product_id: ''
  })

  const [error, setError] = useState({
    coin: false,
    product_id: false
  })

  const onChange = e => {
    setValue({ ...value, [e.target.name]: e.target.value })
    setError({ ...error, [e.target.name]: e.target.value ? false : true })
  }

  const onsubmit = async e => {
    e.preventDefault()

    if (value.coin === '') {
      setError({ ...error, coin: true })
    }
    if (value.product_id === '') {
      setError({ ...error, product_id: true })
    }

    if (value.coin !== '' && value.product_id !== '') {
      await callApi({ uriEndPoint: inAppPurchaseAdd, body: value })
        .then(res => {
          if (res.created) {
            window.history.back()
          }
        })
        .catch(e => console.log(e))
    }
  }
  return (
    <div>
      <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
        <Col className='d-block mb-4 mb-md-0'>
          <h1 className='h2'>Create In App Purchase</h1>
          <p className='mb-0'>Here you can create inapp purchase for SHAH</p>
        </Col>
      </Row>
      <Form onSubmit={onsubmit}>
        <Row>
          <Col md={6} className='mb-3'>
            <Form.Group id='coin'>
              <Form.Label>Coins</Form.Label>
              <Form.Control
                value={value?.coins}
                onChange={onChange}
                type='text'
                name='coin'
                placeholder='Enter number of coins'
              />

              {error.coin && <Form.Text style={{ color: 'red' }}>Please enter number of coins</Form.Text>}
            </Form.Group>
          </Col>
          <Col md={6} className='mb-3'>
            <Form.Group id='product_id'>
              <Form.Label>ProductId</Form.Label>
              <Form.Control
                name='product_id'
                value={value?.product_id}
                onChange={onChange}
                type='text'
                placeholder='ProductId'
              />
              {error.product_id && <Form.Text style={{ color: 'red' }}>Please enter ProductId</Form.Text>}
            </Form.Group>
          </Col>
        </Row>

        <div className='d-flex justify-content-end gap-3'>
          <Button variant='primary' type='submit'>
            Submit
          </Button>
          <Button
            variant='danger'
            type='button'
            onClick={() => {
              window.history.back()
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  )
}
