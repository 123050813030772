import React, { useEffect, useState } from 'react'
import { callApi } from '../../../utils/api-utils'
import { Form } from '@themesberg/react-bootstrap'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const addUniversityEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/coordinator/add'
}
const universityListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/university/list'
}

const AddCoordinator = () => {
  const [addFiled, setAddFiled] = useState({
    name: '',
    university_id: '',
    email: '',
    phone: '',
    password: '',
    status: false
  })
  const [errorHandel, setErrorHandel] = useState(false)
  const [universityList, setUniversityList] = useState(null)
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    phone: '',
    password: ''
  })
  const requiredFields = ['name', 'email', 'password', 'university_id']

  const validateEmail = email => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(email)
  }

  const validatePhone = phone => {
    const phoneRegex = /^\+?\d{1,2}[-\s]?\(?\d{1,4}\)?[-\s]?\d{1,4}[-\s]?\d{1,4}$/
    return phoneRegex.test(phone)
  }

  const validatePassword = password => {
    // Minimum 8 characters, at least one letter, one number, and one special character
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    return passwordRegex.test(password)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const checkValue = requiredFields?.every(field => addFiled[field]?.trim() !== '')

    // Validation checks
    let errors = {
      email: '',
      phone: '',
      password: ''
    }
    if (!validateEmail(addFiled.email)) {
      errors.email = 'Invalid email format'
    }
    if (!validatePhone(addFiled.phone)) {
      errors.phone = 'Invalid phone number format'
    }
    if (!validatePassword(addFiled.password)) {
      errors.password =
        'Password must be at least 8 characters long and include a number, letter, and special character'
    }

    setValidationErrors(errors)

    if (checkValue && !errors.email && !errors.phone && !errors.password) {
      await callApi({ uriEndPoint: addUniversityEndPoint, body: addFiled })
        .then(res => {
          alert(res?.message || 'Add coordinator successfully')
          setAddFiled({ name: '', university_id: '', email: '', phone: '', password: '', status: false })
          window.history.back()
        })
        .catch(e => console.log(e))
    } else {
      setErrorHandel(true)
    }
  }

  useEffect(() => {
    callApi({ uriEndPoint: universityListEndPoint }).then(res => {
      setUniversityList(res)
    })
  }, [])

  const handelChange = e => {
    const { name, value } = e.target

    // Update form field value
    setAddFiled({ ...addFiled, [name]: value })

    // Validate input dynamically on change
    let newValidationErrors = { ...validationErrors }

    // Check each field if it's being changed and validate accordingly
    if (name === 'email') {
      newValidationErrors.email = validateEmail(value) ? '' : 'Invalid email format'
    }
    if (name === 'phone') {
      newValidationErrors.phone = validatePhone(value) ? '' : 'Invalid phone number format'
    }
    if (name === 'password') {
      newValidationErrors.password = validatePassword(value)
        ? ''
        : 'Password must be at least 8 characters long and include a number, letter, and special character'
    }

    setValidationErrors(newValidationErrors)
  }

  const handleStatusChange = e => {
    setAddFiled({ ...addFiled, status: e.target.checked })
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Create Coordinator</h4>
            <button className='btn btn-primary' onClick={() => window.history.back()}>
              Back
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4'>Name *</label>
                <input
                  type='text'
                  name='name'
                  value={addFiled?.name || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  maxLength={150}
                  placeholder='name'
                />
              </div>

              <div className='form-group col-md-6'>
                <label htmlFor='inputPassword4'>University *</label>
                <select
                  id='inputState'
                  value={addFiled?.university_id || ''}
                  onChange={handelChange}
                  name='university_id'
                  className='form-control'
                >
                  <option selected>Choose...</option>
                  {/* Populate options dynamically */}
                  {universityList &&
                    universityList?.allUniversity?.map(university => (
                      <option key={university?._id} value={university?._id}>
                        {university?.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4'>Email *</label>
                <input
                  type='email'
                  name='email'
                  value={addFiled?.email || ''}
                  onChange={handelChange}
                  className='form-control'
                  maxLength={60}
                  id='inputEmail4'
                  placeholder='name@company.com'
                />
                {validationErrors.email && <small style={{ color: 'red' }}>{validationErrors.email}</small>}
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4'>Phone number *</label>
                <input
                  type='text'
                  name='phone'
                  value={addFiled?.phone || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  maxLength={13}
                  placeholder='+12 345 678 910'
                />
                {validationErrors.phone && <small style={{ color: 'red' }}>{validationErrors.phone}</small>}
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4'>Password *</label>
                <input
                  type='password'
                  name='password'
                  value={addFiled?.password || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  placeholder='Abc@1234'
                />
                {validationErrors.password && <small style={{ color: 'red' }}>{validationErrors.password}</small>}
              </div>

              <div className='form-group col-md-6'>
                <label htmlFor='inputState'>Status</label>
                <Form.Check // prettier-ignore
                  type='switch'
                  id='custom-switch'
                  data-toggle='modal'
                  data-target='#exampleModalCenterStatus'
                  checked={addFiled.status}
                  onChange={handleStatusChange}
                />
              </div>
            </div>
            {errorHandel && (
              <h5 style={{ color: 'orangered', textTransform: 'capitalize', marginTop: '10px' }}>* required field</h5>
            )}
            <button type='submit' className='btn btn-primary mt-2'>
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default AddCoordinator
