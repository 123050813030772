import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb } from '@themesberg/react-bootstrap'
import React from 'react'

import { useHistory } from 'react-router-dom'
import { CoordinatorFormTable } from '../../components/free-course-table'

const Coordinator = props => {
  const id = props.match.params.id
  const history = useHistory()

  const coursesPage = () => {
    history.push('/create-coordinator')
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Coordinator</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Coordinator</h4>
          <div className='d-flex justify-content-between  align-items-center'>
            <p className='mb-0'>SHAH Coordinator Management.</p>
            <button className='btn btn-info' onClick={coursesPage}>
              Create Coordinator
            </button>
          </div>
        </div>
      </div>

      <CoordinatorFormTable id={id} />
    </>
  )
}

export default Coordinator
