import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Breadcrumb } from '@themesberg/react-bootstrap'

import { ScholarShipTable } from '../components/Tables'

export default props => {
  const id = props.match.params.id

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Scholarship</Breadcrumb.Item>
          </Breadcrumb>
          {/* <h4>All Scholarship</h4>
          <p className='mb-0'>
            SHAH Students Management.
            {data.length !== 0 ? (
              <CSVLink data={data}>Download Ready</CSVLink>
            ) : (
              <button onClick={getData} className='btn btn-info'>
                Make CSV
              </button>
            )}
          </p> */}
        </div>
      </div>

      <ScholarShipTable id={id} />
    </>
  )
}
