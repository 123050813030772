import React, { useState, useEffect, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Routes } from '../routes'

// components
import Sidebar from '../components/Sidebar-coach'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Preloader from '../components/Preloader'

import ViewScholarship from './components/ViewScholarship'
import axios from 'axios'
import Chat from './Chat'

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000)
    return () => clearTimeout(timer)
  }, [])

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible)

  const toggleSettings = () => {
    setShowSettings(!showSettings)
    localStorage.setItem('settingsVisible', !showSettings)
  }

  return (
    <Route
      {...rest}
      render={props => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <main className='content' style={{ height: '100vh !important' }}>
            <Navbar />
            <Component {...props} />
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
        </>
      )}
    />
  )
}

export default () => (
  <Switch>
    <RouteWithSidebar exact path={Routes.Signin.path} component={Chat} />

    <Suspense fallback={<div>Loading...</div>}>
      <RouteWithSidebar exact path='/scholarship-view/:id' component={ViewScholarship} />
    </Suspense>

    <Redirect to={Routes.NotFound.path} />
  </Switch>
)
