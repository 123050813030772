import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Breadcrumb, Button, Form, Modal } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { AssignCourseFacultyTable } from '../../../components/free-course-table'
import { callApi } from '../../../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const universityListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/university/list'
}

const facultyListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/faculty/list'
}

const assignFreeUserCourse = {
  ...defaults.methods.POST,
  uri: '/api/faculty/assign-course'
}

const facultyApproveStatusUpdate = {
  ...defaults.methods.POST,
  uri: '/api/faculty/update-approval-status'
}

const UniversityStudent = props => {
  // ** id
  const id = props.match.params.id

  // ** states
  const [universityList, setUniversityList] = useState(null)
  const [selectedUniversity, setSelectedUniversity] = useState('all')
  const [gender, setGender] = useState('')
  const [courses, setCourses] = useState([])
  const [showAssignModal, setShowAssignModal] = useState(false)
  const [selectedCourses, setSelectedCourses] = useState([])
  const [selectedStudentIds, setSelectedStudentIds] = useState([])
  const [facultyIdsWithoutApprove, setFacultyIdsWithoutApprove] = useState([])
  const [facultyList, setFacultyList] = useState([])
  const [recallApi, setRecallApi] = useState('')
  const [selectedApproveStatus, setSelectedApproveStatus] = useState(null)

  const [showModal, setShowModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(100) // Default to 10 items per page
  const [totalItems, setTotalItems] = useState(0)

  const handleClose = () => setShowModal(false)

  const handleShow = () => {
    setShowModal(true) // Open the modal when the button is clicked
  }

  // ** common student list

  async function FacultyListData(page = currentPage, itemsPerPage = 100) {
    await callApi({
      uriEndPoint: facultyListEndPoint,
      query: {
        university_id: selectedUniversity === 'all' ? '' : selectedUniversity || ' ',
        gender,
        page, // Current page
        items_per_page: itemsPerPage // Items per page
      }
    })
      .then(res => {
        if (res?.data) {
          setFacultyList(res.data)
          setTotalItems(res?.payload?.pagination?.total)
        }
      })
      .catch(e => console.log(e))
  }

  const handlePageChange = newPage => {
    if (newPage >= 1 && newPage <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(newPage) // Update current page
    }
  }

  const handleItemsPerPageChange = event => {
    setItemsPerPage(Number(event.target.value)) // Update the number of items per page
    setCurrentPage(1) // Reset to the first page when items per page is changed
  }

  useEffect(() => {
    FacultyListData(currentPage, itemsPerPage)
  }, [recallApi, currentPage, itemsPerPage]) // Re-fetch data when page or itemsPerPage changes

  // ** common course list
  async function Refresh() {
    await axios
      .get('/api/free-course/list?type=front-end-api')
      .then(res => {
        const data = res?.data?.allFreeCourse || []
        data.sort(function (a, b) {
          let keyA = a.serial
          let keyB = b.serial

          if (keyA < keyB) return -1
          if (keyA > keyB) return 1
          return 0
        })

        setCourses(data)
      })
      .catch(e => alert(e))
  }

  const handleGenderChange = e => {
    setGender(e.target.value)
  }

  const handleAssignShow = () => setShowAssignModal(true)
  const handleAssignClose = () => {
    setShowAssignModal(false)
  }

  // Store selected course IDs
  const handleCourseSelect = e => {
    const courseId = e.target.value
    setSelectedCourses(prev => (e.target.checked ? [...prev, courseId] : prev.filter(id => id !== courseId)))
  }

  const handleAssign = async () => {
    const studentsToUpdate = facultyList.filter(
      student => selectedStudentIds.includes(student._id) && student.approval_status === 'approve'
    )
    // Extract the IDs of those students who need to be updated
    const idsToUpdate = studentsToUpdate?.map(student => student._id)

    // If no students need to be updated, do not proceed
    if (idsToUpdate.length === 0) {
      alert('No faculty with approval status "approve".')
      return
    }

    const body = {
      // university_id: selectedUniversity,
      // gender,
      courses: selectedCourses,
      faculties: selectedStudentIds
    }

    if (selectedCourses || selectedStudentIds) {
      await callApi({
        uriEndPoint: assignFreeUserCourse,
        body: body
      }).then(res => {
        if (res?.status) {
          setSelectedStudentIds([])
          setShowAssignModal(false)
        }
      })
    }
  }

  // Search button handler common
  const handleSearch = async () => {
    await callApi({
      uriEndPoint: facultyListEndPoint,
      query: {
        university_id: selectedUniversity === 'all' ? '' : selectedUniversity || ' ',
        gender,
        page: currentPage, // Current page
        items_per_page: itemsPerPage // Items per page
      }
    })
      .then(res => {
        if (res?.data) {
          setFacultyList(res.data)
          setTotalItems(res?.payload?.pagination?.total)
        }
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    callApi({ uriEndPoint: universityListEndPoint }).then(res => {
      setUniversityList(res)
    })
  }, [])

  const handelChange = e => {
    setSelectedUniversity(e.target.value)
  }

  useEffect(() => {
    Refresh()
  }, [])

  const [searchTerm, setSearchTerm] = useState('')

  // Filter courses based on search input
  const filteredCourses = courses.filter(course =>
    course.name_of_course.toLowerCase().includes(searchTerm.toLowerCase())
  )

  async function approveStatus() {
    // First, filter the students whose approval status is NOT 'approve'
    const studentsToUpdate = facultyList.filter(
      student => selectedStudentIds.includes(student._id) && student.approval_status !== selectedApproveStatus
    )

    // Extract the IDs of those students who need to be updated
    const idsToUpdate = studentsToUpdate.map(student => student._id)

    // If no students need to be updated, do not proceed
    if (idsToUpdate.length === 0) {
      alert('No students need approval status update.')
      return
    }

    let body = {
      approval_status: selectedApproveStatus,
      faculties: idsToUpdate
    }

    try {
      // Send the API request
      await callApi({ uriEndPoint: facultyApproveStatusUpdate, body })

      // Refresh the data and reset states
      setRecallApi(Math.floor(Math.random(1) * 100).toString())
      setShowModal(false)
      setSelectedApproveStatus(null)
      setSelectedStudentIds([])
    } catch (e) {
      console.error('Error updating approval status:', e)
    }
  }

  const handelChangeApprove = e => {
    // Update form field value
    setSelectedApproveStatus(e.target.value)
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Faculty</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Assign Courses and Approve Faculty Enrollment</h4>
        </div>
      </div>
      <div className=''>
        <div className='d-flex  justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2'>
          <div className='form-group col-md-2'>
            <label htmlFor='inputPassword4'>Select University</label>
            <select
              id='inputState'
              value={selectedUniversity} // value is controlled by state
              onChange={handelChange}
              name='university_id'
              className='form-control'
            >
              <option value='all'>All</option> {/* Default selected value */}
              {/* Populate options dynamically */}
              {universityList &&
                universityList?.allUniversity?.map(university => (
                  <option key={university?._id} value={university?._id}>
                    {university?.name}
                  </option>
                ))}
            </select>
          </div>

          <div className='form-group  col-md-2'>
            <label htmlFor='gender'>Gender</label>
            <select id='gender' value={gender} onChange={handleGenderChange} className='form-control'>
              <option value=''>All</option>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
            </select>
          </div>
          <button className='btn btn-primary' onClick={() => handleSearch()}>
            Search
          </button>
          <button className='btn btn-primary' onClick={() => handleShow()}>
            Approve Status
          </button>
          <button className='btn btn-secondary' onClick={handleAssignShow}>
            Assign
          </button>
        </div>

        <AssignCourseFacultyTable
          id={id}
          selectedUniversity={selectedUniversity}
          selectedStudentIds={selectedStudentIds}
          setSelectedStudentIds={setSelectedStudentIds}
          setFacultyIdsWithoutApprove={setFacultyIdsWithoutApprove}
          facultyIdsWithoutApprove={facultyIdsWithoutApprove}
          facultyList={facultyList}
          setRecallApi={setRecallApi}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          setCurrentPage={handlePageChange} // Pass function to change page
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>

      <Modal show={showAssignModal} onHide={handleAssignClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Searchable Course Selection */}

          <Form.Group controlId='courses' className='mb-2'>
            <Form.Label>Select Course(s)</Form.Label>

            <Form.Control
              type='text'
              placeholder='Search Courses'
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
            <div className='mt-2'>
              {filteredCourses.length === 0 && (
                <Alert variant='warning' className='mt-2'>
                  No courses found.
                </Alert>
              )}

              {filteredCourses.map(course => (
                <Form.Check
                  key={course._id}
                  type='checkbox'
                  label={course.name_of_course}
                  value={course._id}
                  checked={selectedCourses.includes(course._id)}
                  onChange={e => handleCourseSelect(e, course._id)}
                />
              ))}
            </div>
          </Form.Group>

          {/* <Form.Group controlId='course'>
            <Form.Label>Select Course</Form.Label>
            {courses.map(course => (
              <Form.Check
                type='checkbox'
                label={course.name_of_course}
                value={course._id}
                onChange={handleCourseSelect}
              />
            ))}
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleAssignClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleAssign} disabled={!selectedStudentIds}>
            Confirm Assign Course
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='approvalStatus'>
              <Form.Label>Select Status</Form.Label>
              <Form.Control as='select' value={selectedApproveStatus || ''} onChange={handelChangeApprove}>
                <option value='' disabled>
                  Choose...
                </option>
                <option value='approve'>Approve</option>
                <option value='pending'>Pending</option>
                <option value='cancel'>Cancel</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={approveStatus} disabled={!selectedApproveStatus || !selectedCourses}>
            Yes, approve it!
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UniversityStudent
