import React, { useState, useEffect } from 'react'
import { Col, Row, Form, Container } from '@themesberg/react-bootstrap'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'

import { MultiSelect } from 'primereact/multiselect'

import 'primereact/resources/themes/lara-light-cyan/theme.css'

import S3FileUpload from 'react-s3'
window.Buffer = window.Buffer || require('buffer').Buffer

import axios from 'axios'

export default props => {
  const [nameOfCourse, setnameOfCourse] = useState('')
  const [id] = useState(props.match.params.id)

  const [courseid, setCourseId] = useState('')
  const [clinicalPonits, setClinicalPonits] = useState('')

  const [chapterNumber, setChapterNumber] = useState('')
  const [indianStudentRegularPrice, setIndianStudentRegularPrice] = useState('')
  const [indianStudentSellingPrice, setIndianStudentSellingPrice] = useState('')
  const [indianHomeopathicRegularPrice, setIndianHomeopathicRegularPrice] = useState('')
  const [indianHomeopathicSellingPrice, setIndianHomeopathicSellingPrice] = useState('')
  const [internationalRegularPrice, setInternationalRegularPrice] = useState('')
  const [internationalSellingPrice, setInternationalSellingPrice] = useState('')
  const [oneLineSentence, setOneLineSentence] = useState('')
  const [chapterRating, setChapterRating] = useState('')
  const [chapterDuration, setChapterDuration] = useState('')
  const [chapterOverView, setChapterOverView] = useState('')
  const [curriculumUrl, setCurriculumUrl] = useState('')
  const [overViewId, setOverViewId] = useState('')

  const [HeroImg, setHeroImg] = useState('')

  const [courses, setCourses] = useState([])
  const [selectedCourse, setSelectedCourse] = useState(null)

  useEffect(() => {
    Refresh()
  }, [])

  async function Refresh() {}

  const config = {
    bucketName: 'courseimg',
    region: 'ap-south-1',
    accessKeyId: 'AKIAZTNEOKHHTM2HHSWZ',
    secretAccessKey: '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'
  }

  function UploadImages(event) {
    const image = event.target.files[0]
    S3FileUpload.uploadFile(image, config)
      .then(data => {
        setHeroImg(data.location)
        console.log(data.location)
      })
      .catch(err => console.error(err))
  }

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const handleEditorChange = state => {
    setEditorState(state)
  }

  function RecieveData(html) {
    const contentBlock = htmlToDraft(html)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      const editorState = EditorState.createWithContent(contentState)
      setEditorState(editorState)
    }
  }

  useEffect(() => {
    LoadCourse()
  }, [])

  async function LoadCourse() {
    let allCourse = []
    try {
      await axios
        .get('/api/course?type=front-end-api')
        .then(res => {
          const data = res.data.courses
          data.sort(function (a, b) {
            let keyA = a.serial
            let keyB = b.serial

            if (keyA < keyB) return -1
            if (keyA > keyB) return 1
            return 0
          })
          allCourse = data
          setCourses(data)
        })
        .catch(e => alert(e))
      axios
        .post(`/api/course/chapter/`, {
          id: id
        })
        .then(res => {
          setnameOfCourse(res.data.chapterName)
          setCourseId(res.data.courseID)
          RecieveData(res.data.chapterDes)
          setClinicalPonits(res.data.clinicalPonits)
          setChapterNumber(res.data.chapterNumber)
          setIndianStudentRegularPrice(res.data.indian_student_regular_price)
          setIndianStudentSellingPrice(res.data.indian_student_selling_price)
          setIndianHomeopathicRegularPrice(res.data.indian_homeopathic_regular_price)
          setIndianHomeopathicSellingPrice(res.data.indian_homeopathic_selling_price)
          setInternationalRegularPrice(res.data.international_regular_price)
          setInternationalSellingPrice(res.data.international_selling_price)
          setHeroImg(res?.data?.chapter_thumbnail || '')
          setOneLineSentence(res?.data?.one_line_sentence || '')
          setChapterRating(res?.data?.rating || '')
          setChapterDuration(res?.data?.chapter_duration || '')
          setChapterOverView(res?.data?.over_view || '')
          setCurriculumUrl(res?.data?.curriculum_url || '')
          setOverViewId(res?.data?.mobile_id || '')
          const selectedCourseFilter = allCourse.filter(i =>
            res?.data?.related_course_array?.some(item => i._id == item)
          )
          setSelectedCourse(selectedCourseFilter)
        })
        .catch(e => {
          alert(e)
        })
    } catch (error) {
      console.log(`🚀  ~ file: EditChapter.js:134 ~ LoadCourse ~ error:`, error)
    }
  }

  function CourseCreation(e) {
    e.preventDefault()
    convertContentToHTML()
  }

  // function convertToSlug(Text) {
  //   return Text.toLowerCase()
  //     .replace(/ /g, '-')
  //     .replace(/[^\w-]+/g, '')
  // }

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    const filterId = selectedCourse.map(i => i._id)
    axios
      .post(`/api/course/update/chapter`, {
        id: id,
        chapterName: nameOfCourse,
        chapterDes: currentContentAsHTML,
        courseID: courseid,
        clinicalPonits: clinicalPonits,
        chapterNumber: chapterNumber,
        indian_student_regular_price: indianStudentRegularPrice,
        indian_student_selling_price: indianStudentSellingPrice,
        indian_homeopathic_regular_price: indianHomeopathicRegularPrice,
        indian_homeopathic_selling_price: indianHomeopathicSellingPrice,
        international_regular_price: internationalRegularPrice,
        international_selling_price: internationalSellingPrice,
        chapter_thumbnail: HeroImg,
        related_course_array: filterId,
        one_line_sentence: oneLineSentence,
        chapter_duration: chapterDuration,
        chapter_poster_image: HeroImg,
        rating: chapterRating,
        over_view: chapterOverView,
        curriculum_url: curriculumUrl,
        mobile_id: overViewId
      })
      .then(() => {
        alert('Chapter Updated')
        window.location.href = `#/mychapters/${courseid}`
      })
      .catch(e => alert(e))
  }

  return (
    <article>
      <Container className='px-0'>
        <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
          <Col className='d-block mb-4 mb-md-0'>
            <h1 className='h2'>Edit Chapter</h1>
            <p className='mb-0'>
              Here you can edit chapters for SHAH or <a href={'#/mychapters/' + courseid}>Back to chapters</a>
            </p>
          </Col>
        </Row>

        <Form onSubmit={CourseCreation}>
          <Form.Group className='mb-3'>
            <Form.Label>Chapter Name </Form.Label>
            <Form.Control
              type='text'
              value={nameOfCourse}
              onChange={e => setnameOfCourse(e.target.value)}
              placeholder='Your Course name'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>One Line Sentence</Form.Label>
            <Form.Control
              type='text'
              value={oneLineSentence}
              onChange={e => setOneLineSentence(e.target.value)}
              placeholder='One Line Sentence'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Chapter Rating</Form.Label>
            <Form.Control
              type='text'
              value={chapterRating}
              onChange={e => setChapterRating(e.target.value)}
              placeholder='Chapter Rating'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Chapter Duration</Form.Label>
            <Form.Control
              type='text'
              value={chapterDuration}
              onChange={e => setChapterDuration(e.target.value)}
              placeholder='Chapter Duration'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Course Overview</Form.Label>
            <Form.Control
              type='text'
              value={chapterOverView}
              onChange={e => setChapterOverView(e.target.value)}
              placeholder='Course Overview'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Course Overview App (Vdocipher Id)</Form.Label>
            <Form.Control
              type='text'
              value={overViewId}
              onChange={e => setOverViewId(e.target.value)}
              placeholder='Course Overview App (Vdocipher Id)'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Curriculum Url</Form.Label>
            <Form.Control
              type='text'
              value={curriculumUrl}
              onChange={e => setCurriculumUrl(e.target.value)}
              placeholder='Curriculum Url'
            />
          </Form.Group>
          <Form.Group className='mb-3 card flex justify-content-center'>
            <MultiSelect
              value={selectedCourse}
              onChange={e => setSelectedCourse(e.value)}
              options={courses}
              optionLabel='nameOfCourse'
              placeholder='Select Course'
              maxSelectedLabels={3}
              className='w-full md:w-20rem'
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Add Description</Form.Label>
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName='demo-wrapper'
              editorClassName='demo-editor'
              toolbarClassName='toolbar-class'
            />
            <Form.Group style={{ marginTop: 4 }} className='mb-3'>
              <Form.Label>Clinical Points ( 1 = default | 0 = hide )</Form.Label>
              <Form.Control
                value={clinicalPonits}
                onChange={e => setClinicalPonits(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Clinical Points'
                required
              />
            </Form.Group>
            <Form.Group style={{ marginTop: 4 }} className='mb-3'>
              <Form.Label>Chapter Number</Form.Label>
              <Form.Control
                value={chapterNumber}
                onChange={e => setChapterNumber(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Chapter Number'
                required
              />
            </Form.Group>

            <Form.Label style={{ marginTop: 4 }}>Chapter Hero Image</Form.Label>
            <div className='mb-3'>
              {HeroImg?.length === 0 ? (
                <input onChange={UploadImages} style={{ marginTop: 4 }} type='file' placeholder='Chapter Img URL' />
              ) : (
                <>
                  <p className='btn btn-success'>Image Added</p>
                  <p
                    onClick={() => {
                      setHeroImg('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='btn btn-danger'
                  >
                    Replace
                  </p>
                </>
              )}
            </div>
            <Form.Group className='mb-3'>
              <Form.Label>Indian Regular Price (INR)</Form.Label>
              <Form.Control
                type='text'
                value={indianStudentRegularPrice}
                onChange={e => setIndianStudentRegularPrice(e.target.value)}
                placeholder='Lesson Regular Price'
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Indian Discounted Price (INR)</Form.Label>
              <Form.Control
                type='text'
                value={indianStudentSellingPrice}
                onChange={e => setIndianStudentSellingPrice(e.target.value)}
                placeholder='Lesson Regular Price'
              />
            </Form.Group>
            {/* <Form.Group className='mb-3'>
              <Form.Label>Indian Homeopaths Regular Price (INR)</Form.Label>
              <Form.Control
                type='text'
                value={indianHomeopathicRegularPrice}
                onChange={e => setIndianHomeopathicRegularPrice(e.target.value)}
                placeholder='Lesson Regular Price'
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Indian Homeopaths Discounted Price (INR)</Form.Label>
              <Form.Control
                type='number'
                value={indianHomeopathicSellingPrice}
                onChange={e => setIndianHomeopathicSellingPrice(e.target.value)}
                placeholder='Lesson Regular Price'
              />
            </Form.Group> */}
            <Form.Group className='mb-3'>
              <Form.Label>International Regular Price (Dollar)</Form.Label>
              <Form.Control
                type='number'
                value={internationalRegularPrice}
                onChange={e => setInternationalRegularPrice(e.target.value)}
                placeholder='Lesson Regular Price'
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>International Discounted Price (Dollar)</Form.Label>
              <Form.Control
                type='number'
                value={internationalSellingPrice}
                onChange={e => setInternationalSellingPrice(e.target.value)}
                placeholder='Lesson Regular Price'
              />
            </Form.Group>
            <Form.Group style={{ marginTop: 4, display: 'none' }} className='mb-3'>
              <Form.Label>Details</Form.Label>
              <Form.Control
                value={courseid}
                onChange={e => setCourseId(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Your Course id'
                required
              />
            </Form.Group>
            <button className='btn btn-info' style={{ marginTop: 12 }} type='submit'>
              Update Chapter
            </button>
          </Form.Group>
        </Form>
      </Container>
    </article>
  )
}
