import React, { useEffect, useState } from 'react'
import { callApi } from '../../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const editEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/questions/update/questions/:id'
}
const viewEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/questions/view/questions/:id'
}

export default props => {
  const [addFiled, setAddFiled] = useState({})

  const handleSubmit = async e => {
    e.preventDefault()
    await callApi({ uriEndPoint: editEndPoint, body: addFiled, pathParams: { id: props?.match?.params?.id || null } })
      .then(res => {
        alert(res?.message || 'Update question successfully')
      })
      .catch(e => console.log(e))
  }

  const handelChange = e => {
    setAddFiled({ ...addFiled, [e.target.name]: e.target.value })
  }

  const callApiFunc = async () => {
    await callApi({ uriEndPoint: viewEndPoint, pathParams: { id: props?.match?.params?.id || null } })
      .then(res => {
        setAddFiled(res?.data || '')
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    callApiFunc()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Edit TypoForm</h4>
            <button className='btn btn-primary' onClick={() => window.history.back()}>
              Back
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4'>Question</label>
                <input
                  type='text'
                  name='question'
                  value={addFiled?.question || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  placeholder='Question'
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputPassword4'>Input Type</label>
                <select
                  id='inputState'
                  value={addFiled?.input_type || ''}
                  onChange={handelChange}
                  name='input_type'
                  className='form-control'
                >
                  <option selected>Choose...</option>
                  <option value='text'>Text</option>
                  <option value='email'>Email</option>
                  <option value='number'>Number</option>
                  <option value='phone'>phone</option>
                </select>
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputPassword4'>Error</label>
                <input
                  type='Text'
                  name='error'
                  value={addFiled?.error || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputPassword4'
                  placeholder='Error'
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputState'>State</label>
                <select
                  id='inputState'
                  value={addFiled?.type || ''}
                  onChange={handelChange}
                  name='type'
                  className='form-control'
                >
                  <option selected>Choose...</option>
                  <option value='contact'>Contact</option>
                  <option value='course'>Course</option>
                </select>
              </div>
            </div>

            <button type='submit' className='btn btn-primary mt-2'>
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  )
}
