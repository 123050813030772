import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, Button, Modal } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { AssignBadgesTable } from '../../components/free-course-table'
import { callApi } from '../../utils/api-utils'

const rangeData = [
  { _id: 1, value: '100 to 90', label: '100 to 90' },
  { _id: 2, value: '90 to 80', label: '90 to 80' },
  { _id: 3, value: '80 to 70', label: '80 to 70' },
  { _id: 4, value: '70 to 60', label: '70 to 60' },
  { _id: 5, value: '60 to 50', label: '60 to 50' },
  { _id: 6, value: '50 to 40', label: '50 to 40' },
  { _id: 7, value: '40 to 30', label: '40 to 30' },
  { _id: 8, value: '30 to 20', label: '30 to 20' },
  { _id: 9, value: '20 to 10', label: '20 to 10' },
  { _id: 10, value: '10 to 0', label: '10 to 0' }
]

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const studentListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/enroll-free-course/course-list'
}
const assignBadgeStudent = {
  ...defaults.methods.POST,
  uri: '/api/free-course-badge/add-badge'
}

const academicYearData = [
  { u_id: 1, value: '1', label: '1st year' },
  { u_id: 2, value: '2', label: '2nd year' },
  { u_id: 3, value: '3', label: '3rd year' },
  { u_id: 4, value: '4', label: '4th year' },
  { u_id: 5, value: '5', label: 'Intern' },
  { u_id: 6, value: '6', label: 'MD Part 1' },
  { u_id: 7, value: '7', label: 'MD Part 2' }
]

const AssignBadges = props => {
  // ** id
  const id = props.match.params.id

  // ** states
  const [academicYear, setAcademicYear] = useState('')
  const [courses, setCourses] = useState([])
  const [selectedStudentIds, setSelectedStudentIds] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [studentList, setStudentList] = useState([])
  const [recallApi, setRecallApi] = useState('')

  // ** badge State
  const [showAssignBadgeModal, setShowAssignBadgeModal] = useState(false)
  const [range, setRange] = useState('')
  const [courseData, setCourseData] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(100) // Default to 10 items per page
  const [totalItems, setTotalItems] = useState(0)

  // ** common student list

  async function StudentListData(page = currentPage, itemsPerPage = 100) {
    await callApi({
      uriEndPoint: studentListEndPoint,
      query: {
        pursuing_year: academicYear,
        course_id: courseData,
        range,
        page, // Current page
        items_per_page: itemsPerPage // Items per page
      }
    })
      .then(res => {
        if (res?.data) {
          setStudentList(res.data)
          setTotalItems(res?.payload?.pagination?.total)
        }
      })
      .catch(e => console.log(e))
  }

  const handlePageChange = newPage => {
    if (newPage >= 1 && newPage <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(newPage) // Update current page
    }
  }

  const handleItemsPerPageChange = event => {
    setItemsPerPage(Number(event.target.value)) // Update the number of items per page
    setCurrentPage(1) // Reset to the first page when items per page is changed
  }

  useEffect(() => {
    StudentListData(currentPage, itemsPerPage)
  }, [recallApi, currentPage, itemsPerPage])

  // ** common course list
  async function Refresh() {
    await axios
      .get('/api/free-course/list?type=front-end-api')
      .then(res => {
        const data = res?.data?.allFreeCourse || []
        data.sort(function (a, b) {
          let keyA = a.serial
          let keyB = b.serial

          if (keyA < keyB) return -1
          if (keyA > keyB) return 1
          return 0
        })

        setCourses(data)
      })
      .catch(e => alert(e))
  }

  // ** common academic year
  // Add event handlers for new filters
  const handleAcademicYearChange = e => {
    setAcademicYear(e.target.value)
  }

  // ** asign badge
  const handleRangeChange = e => {
    setRange(e.target.value)
  }

  const handleAssignBadgeShow = () => setShowAssignBadgeModal(true)
  const handleAssignBadgeClose = () => {
    setShowAssignBadgeModal(false)
    // setRange('')
    // setCourseData('')
  }

  const handleAssignBadge = async () => {
    const body = {
      course_id: courseData,
      students: selectedStudentIds,
      range: range,
      pursuing_year: academicYear
    }

    await callApi({
      uriEndPoint: assignBadgeStudent,
      body: body
    }).then(res => {
      if (res) {
        setShowAssignBadgeModal(false)
        setRange('')
        setCourseData('')
        setSelectedStudentIds([])
      }
    })
  }

  // Search button handler common
  const handleSearch = async () => {
    await callApi({
      uriEndPoint: studentListEndPoint,
      query: {
        pursuing_year: academicYear,
        course_id: courseData,
        range,
        page: currentPage,
        items_per_page: itemsPerPage // Items per page
      }
    })
      .then(res => {
        if (res?.data) {
          setStudentList(res.data)
          setTotalItems(res?.payload?.pagination?.total)
        }
      })
      .catch(e => console.log(e))
  }

  // Search button handler for assign badge

  // const handleSearchBadgeStudent = async () => {
  //   console.log('first')
  //   await callApi({
  //     uriEndPoint: studentListEndPoint,
  //     query: {
  //       university_id: selectedUniversity === 'all' ? '' : selectedUniversity || ' ',
  //       pursuing_year: academicYear,
  //       gender
  //     }
  //   })
  //     .then(res => {
  //       console.log('🚀 ~ StudentListData ~ res:', res)
  //       if (res?.data) {
  //         setStudentList(res.data)
  //       }
  //     })
  //     .catch(e => console.log(e))
  // }

  const handelCourseChange = e => {
    setCourseData(e.target.value)
  }

  useEffect(() => {
    Refresh()
  }, [])

  // Effect to set default dates if not provided
  useEffect(() => {
    const today = new Date()
    const end = new Date(today)
    end.setMonth(end.getMonth() + 10)

    // If no startDate is selected, set it to today's date
    if (!startDate) {
      setStartDate(today.toISOString().split('T')[0]) // Formats the date as 'YYYY-MM-DD'
    }

    // If no endDate is selected, set it to 10 months from today
    if (!endDate) {
      setEndDate(end.toISOString().split('T')[0]) // Formats the date as 'YYYY-MM-DD'
    }
  }, [startDate, endDate])

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Students</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Student List with Assigned Courses - Proceed to Assign Badges</h4>
        </div>
      </div>
      <div className=''>
        <div className='d-flex  justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2'>
          <div className='form-group col-md-2'>
            <label htmlFor='inputPassword4'>Select Course</label>
            <select
              id='inputState'
              value={courseData} // value is controlled by state
              onChange={handelCourseChange}
              name='course'
              className='form-control'
            >
              <option value=''>All</option> {/* Default selected value */}
              {/* Populate options dynamically */}
              {courses &&
                courses?.map(item => (
                  <option key={item?._id} value={item?._id}>
                    {item?.name_of_course}
                  </option>
                ))}
            </select>
          </div>

          <div className='form-group col-md-2'>
            <label htmlFor='academicYear'>Academic Year</label>
            <select id='academicYear' value={academicYear} onChange={handleAcademicYearChange} className='form-control'>
              <option value=''>All</option>
              {/* Populate academic year options */}
              {academicYearData?.map(item => (
                <option key={item?.u_id} value={item?.value}>
                  {item?.label}
                </option>
              ))}
            </select>
          </div>
          <div className='form-group col-md-2'>
            <label htmlFor='range'>Select Range</label>
            <select id='range' value={range} onChange={handleRangeChange} className='form-control'>
              <option value=''>All</option>
              {rangeData?.map(item => {
                return (
                  <option key={item?._id} value={item?.value}>
                    {item?.label}
                  </option>
                )
              })}
            </select>
          </div>
          <button className='btn btn-primary' onClick={() => handleSearch()}>
            Search
          </button>
          <button className='btn btn-secondary' onClick={handleAssignBadgeShow}>
            Assign Badge
          </button>
        </div>

        <AssignBadgesTable
          id={id}
          selectedStudentIds={selectedStudentIds}
          setSelectedStudentIds={setSelectedStudentIds}
          studentList={studentList}
          setRecallApi={setRecallApi}
          StudentListData={() => StudentListData()}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          setCurrentPage={handlePageChange} // Pass function to change page
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>

      <Modal show={showAssignBadgeModal} onHide={handleAssignBadgeClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Badge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <>
            <Form.Group controlId='startDate' className='mb-2'>
              <Form.Label>Start Date</Form.Label>
              <Form.Control type='date' value={startDate} onChange={handleStartDateChange} />
            </Form.Group>

            <Form.Group controlId='endDate' className='mb-2'>
              <Form.Label>End Date</Form.Label>
              <Form.Control type='date' value={endDate} onChange={handleEndDateChange} />
              {dateError && (
                <Alert variant='danger' className='mt-2'>
                  End Date cannot be earlier than Start Date.
                </Alert>
              )}
            </Form.Group>

            <Form.Group controlId='watchCount' className='mb-2'>
              <Form.Label>Watch Count</Form.Label>
              <Form.Control type='number' value={watchCount} onChange={e => setWatchCount(e.target.value)} required />
            </Form.Group>
          </> */}
          <h5>Are you sure you want to assign badges to {selectedStudentIds?.length} student</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleAssignBadgeClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleAssignBadge} disabled={!range || !courseData || !selectedStudentIds}>
            Yes, Assign Badges!
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AssignBadges
