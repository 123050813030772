import React, { useEffect, useRef, useState } from 'react'
import { callApi } from '../../utils/api-utils'
import S3FileUpload from 'react-s3'
window.Buffer = window.Buffer || require('buffer').Buffer

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const editEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/staff/update-staff/:id'
}

const coachViewEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/staff/view-staff/:id'
}

export default props => {
  const [addFiled, setAddFiled] = useState({})
  // const [showPassword, setShowPassword] = useState(false)

  const config = {
    bucketName: 'coachshah',
    region: 'ap-south-1',
    accessKeyId: 'AKIAZTNEOKHHTM2HHSWZ',
    secretAccessKey: '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'
  }

  const [fileSend, setFileSend] = useState('')

  const fileInputRef = useRef(null)

  const fileName = fileSend?.split('/')[3]
  if (fileName) {
    S3FileUpload.deleteFile(fileName, config)
      .then(res => {
        console.log(res)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const handleFileChange = e => {
    const selectedFile = e.target.files[0]

    if (selectedFile) {
      S3FileUpload.uploadFile(selectedFile, config)
        .then(data => {
          setFileSend(data.location)
        })
        .catch(error => {
          console.error('Error creating folder:', error)
        })
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    addFiled.image = fileSend
    await callApi({ uriEndPoint: editEndPoint, body: addFiled, pathParams: { id: props?.match?.params?.id || null } })
      .then(res => {
        alert(res?.message || 'Update question successfully')
        window.history.back()
      })
      .catch(e => console.log(e))
  }

  const handelChange = e => {
    setAddFiled({ ...addFiled, [e.target.name]: e.target.value })
  }

  const callApiFunc = async () => {
    await callApi({ uriEndPoint: coachViewEndPoint, pathParams: { id: props?.match?.params?.id || null } })
      .then(res => {
        setAddFiled(res?.data || '')
        setFileSend(res?.data?.image)
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    callApiFunc()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Edit Coach</h4>
            <button className='btn btn-primary' onClick={() => window.history.back()}>
              Back
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='form-group col-md-6'>
                <label htmlFor='first_name'>First Name*</label>
                <input
                  type='text'
                  name='first_name'
                  value={addFiled?.first_name || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='first_name'
                  placeholder='Name'
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='last_name'>Last Name*</label>
                <input
                  type='text'
                  name='last_name'
                  value={addFiled?.last_name || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='last_name'
                  placeholder='Name'
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='email'>Email*</label>
                <input
                  type='email'
                  name='email'
                  value={addFiled?.email || ''}
                  onChange={handelChange}
                  className='form-control'
                  required
                  id='email'
                  placeholder='demo@gmail.com'
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='phone'>Phone*</label>
                <input
                  type='phone'
                  name='phone'
                  value={addFiled?.phone || ''}
                  onChange={handelChange}
                  onInput={e => {
                    e.target.value = e.target.value.slice(0, 10)
                  }}
                  className='form-control'
                  id='phone'
                  placeholder='9999999999'
                />
              </div>
              {/* <div className='form-group col-md-6'>
                <label htmlFor='password'>Password*</label>
                <div className='d-flex align-items-center position-relative'>
                  <input
                    autoComplete={false}
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    value={addFiled?.password || ''}
                    onChange={handelChange}
                    className='form-control'
                    id='password'
                    placeholder='Password'
                  />
                  <span
                    className='eye-icon position-absolute '
                    style={{ right: '10px', cursor: 'pointer' }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <i class='fa-solid fa-eye'></i> : <i class='fa-solid fa-eye-slash'></i>}
                  </span>
                </div>
              </div> */}
              {fileSend && (
                <div className='col-md-12' style={{ margin: '1rem 0' }}>
                  <div style={{ width: '200px', height: '200px' }}>
                    <img src={fileSend} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </div>
                </div>
              )}
              <input
                type='file'
                ref={fileInputRef}
                style={{ margin: !fileSend ? '1rem 0' : '0' }}
                accept='.jpeg, .jpg, .png'
                onChange={handleFileChange}
              />
            </div>

            <button type='submit' className='btn btn-primary mt-2'>
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  )
}
